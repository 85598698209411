import * as React from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Dialog,
  DialogContent,
  FormHelperText,
  TextField 
} from '@mui/material';
import Typography from '../components/Typography';
import Button from '../components/Button';
import withRoot from '../withRoot';
import * as Yup from 'yup';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { auth, db } from '../../environment';
import { useRecoilState } from 'recoil';
import { authState } from '../../store';
import { Redirect } from "react-router-dom";
import { doc, getDoc, setDoc } from 'firebase/firestore';

function Login() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const [authS, setAuthS] = useRecoilState(authState);

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const [sent, setSent] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object({
      password: Yup
        .string()
        .max(255)
        .required('A participant code is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        await signInWithEmailAndPassword(auth, values.password + '@project.velograph.app', values.password).then(async (userCredential) => {
          const { user } = userCredential;

          // init database with user if not in db
          const userRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          if (typeof docSnap.data() == 'undefined') {
            await setDoc(userRef, {'trips': [], 'subtrips': [], 'failed_files': []});
          }
          
          setAuthS({
            initialised: true,
            authenticated: true,
            user: user.uid,
            email: values.password + '@project.velograph.app'
          })

        });
        setLoginSuccess(true);
      } catch (err) {
        if (err.message == "Firebase: Error (auth/user-not-found).") {
          // helpers.setErrors({ submit: 'Email Not Recognised' });
          helpers.setErrors({ submit: 'Participant Code Incorrect' });
        } else if (err.message == "Firebase: Error (auth/wrong-password).") {
          helpers.setErrors({ submit: 'Participant Code Incorrect' });
        } else if (err.message == "Firebase: Error (auth/invalid-email).") {
          helpers.setErrors({ submit: 'Participant Code Incorrect' });
        } else {
          helpers.setErrors({ submit: err.message });
        }
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    }
  });

  if (loginSuccess) {
    return <Redirect to="/dashboard" />
  }

  return (
    <>
      <Button onClick={handleOpen('body')} color="secondary" size="large" variant="outlined" sx={{color: '#fe6700'}}>
          Login
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" fullWidth maxWidth="sm">
        <DialogContent dividers={scroll === 'paper'}>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Login
            </Typography>
          </Box>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Participant Code"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
            />
            {formik.errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {formik.errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary" 
              >
                Log In
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
    
  );
}

export default withRoot(Login);
