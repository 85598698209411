import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../components/Button';
import Typography from '../components/Typography';
import { db } from '../../environment';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { useRecoilState } from 'recoil';
import { authState } from '../../store';
import bike1 from '../../static/bike1c.png';
import bike2 from '../../static/bike2c.png';
import bike3 from '../../static/bike3c.png';
import bike4 from '../../static/bike4.png';
import toast from 'react-hot-toast';

const DetailsDialog = ({detailsDialogOpen, setDetailsDialogOpen, first}) => {

  const [authS, setAuthS] = useRecoilState(authState);
  const [userData, setUserData] = React.useState({});

  React.useEffect(async () => {    
    const unsub = onSnapshot(doc(db, 'users', authS.user), (doc) => {
      setUserData(doc.data());
    });
    return unsub;
  }, []);

  return (
    <>
      <Dialog
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        fullWidth
        maxWidth='md'
      >
        <Box sx={{ mt: 3, mb: 0 }}>
          <Typography variant="h3"  marked="center" align="center">
            Rider Details
          </Typography>
        </Box>
        <Formik
          initialValues={{
            age: userData.age || '',
            gender: userData.gender || '',
            typebike: userData.typebike || '',
            typepedals: userData.typepedals || '',
            senslong: userData.senslong || 0,
            sensheight: userData.sensheight || 0,
            senslat: userData.senslat || 0,
            handlebar: userData.handlebar || 0,
          }}
          validationSchema={Yup.object({
            age: Yup.number()
              .integer('Must be a number')
              .typeError('You must specify a number')
              .required("Required")
              .min(18, 'Must be over 18')
              .max(110, 'You are not that old'),
            gender: Yup.string()
              .required("Required"),
            typebike: Yup.string()
              .required("Required"),
            typepedals: Yup.string()
              .required("Required"),
            senslong: Yup.number()
              .integer('Must be a number')
              .typeError('You must specify a number')
              .required("Required")
              .min(35, 'Must be greater than 35')
              .max(150, 'Must be less than 150'),
            sensheight: Yup.number()
              .integer('Must be a number')
              .typeError('You must specify a number')
              .required("Required")
              .min(-50, 'Must be greater than -50')
              .max(50, 'Must be less than 50'),
            senslat: Yup.number()
              .integer('Must be a number')
              .typeError('You must specify a number')
              .required("Required")
              .min(-40, 'Must be greater than -40')
              .max(40, 'Must be less than 40'),
            handlebar: Yup.number()
              .integer('Must be a number')
              .typeError('You must specify a number')
              .required("Required")
              .min(1, 'Must be greater than 0')
              .max(50, 'Must be less than 50'),
          })}
          onSubmit={async (values, { resetForm, setSubmitting, setErrors, setStatus }) => {
            try {
              const newUserData = {
                age: parseInt(values.age),
                gender: values.gender,
                typebike: values.typebike,
                typepedals: values.typepedals,
                senslong: parseInt(values.senslong),
                sensheight: parseInt(values.sensheight),
                senslat: parseInt(values.senslat),
                handlebar: parseInt(values.handlebar),
              }
              await updateDoc(doc(db, 'users', authS.user), newUserData);
                      
              // resetForm();
              setStatus({ success: true });
              toast.success('Details Successfully Updated')
              // setSubmitting(false);
              setDetailsDialogOpen(false);
            } catch (err) {
              console.log('Error:', err);
              toast.error('Detail Update Failed')
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ handleBlur, handleChange, handleSubmit, setFieldValue, errors, touched, values, isSubmitting}) => (
            <form onSubmit={handleSubmit} >
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {first ?
                      <Typography>
                        Before uploading your first trip we require some initial details. This information will be retained by the project team so your demographic, bicycle, and device mounting details can be anonymously associated with your uploaded data.
                      </Typography>
                    :
                      <Typography>
                        Edit your details. This information will be retained by the project team so your demographic, bicycle, and device mounting details can be anonymously associated with your uploaded data.
                      </Typography>
                    }
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={Boolean(touched.age && errors.age)}
                      helperText={touched.age && errors.age}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Age"
                      name="age"
                      value={values.age}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Gender"
                      name="gender"
                      value={values.gender}
                      fullWidth>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      error={Boolean(touched.typebike && errors.typebike)}
                      helperText={touched.typebike && errors.typebike}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Bicycle Type"
                      name="typebike"
                      value={values.typebike}
                      fullWidth>
                      <MenuItem value="Road">Road</MenuItem>
                      <MenuItem value="Commuter">Commuter</MenuItem>
                      <MenuItem value="Hybrid">Hybrid</MenuItem>
                      <MenuItem value="eBike">eBike</MenuItem>
                      <MenuItem value="Mountain">Mountain</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      select
                      error={Boolean(touched.typepedals && errors.typepedals)}
                      helperText={touched.typepedals && errors.typepedals}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Pedal Type"
                      name="typepedals"
                      value={values.typepedals}
                      fullWidth>
                      <MenuItem value="Flat">Flat</MenuItem>
                      <MenuItem value="Stirrup">Stirrup</MenuItem>
                      <MenuItem value="Harness">Harness</MenuItem>
                      <MenuItem value="Clip-in">Clip-in</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={Boolean(touched.senslong && errors.senslong)}
                      helperText={touched.senslong && errors.senslong}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Longitudinal difference (cm)"
                      name="senslong"
                      value={values.senslong}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={Boolean(touched.sensheight && errors.sensheight)}
                      helperText={touched.sensheight && errors.sensheight}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Height difference (cm)"
                      name="sensheight"
                      value={values.sensheight}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={Boolean(touched.senslat && errors.senslat)}
                      helperText={touched.senslat && errors.senslat}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Rear lateral offset (cm)"
                      name="senslat"
                      value={values.senslat}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      error={Boolean(touched.handlebar && errors.handlebar)}
                      helperText={touched.handlebar && errors.handlebar}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Handlebar offset (cm)"
                      name="handlebar"
                      value={values.handlebar}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      src={bike1}
                      alt="Longitudinal difference"
                      width='100%'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      src={bike2}
                      alt="Height difference"
                      width='100%'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      src={bike3}
                      alt="Rear lateral offset"
                      width='100%'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      src={bike4}
                      alt="Handlebar offset"
                      width='100%'
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                  color="secondary" variant="contained" size="large"
                  fullWidth
                  sx={{m: 2, mt: 0}}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
      </Formik>
    </Dialog>
  </>
        
  );
}

export default DetailsDialog;
