import * as React from 'react';
import Container from '@mui/material/Container';
import {
  Box,
  Stack
} from '@mui/material';

import Faq from './Faq';
import Terms from './Terms';
import Privacy from './Privacy';

export default function AppFooter() {

  return (
    <Box component="footer" sx={{ display: 'flex', bgcolor: 'secondary.light'}}>
      <Container sx={{ my: 1, display: 'flex', justifyContent: 'space-around' }}>
        {/* <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Terms />
          <Privacy />
          <Faq />
        </Stack> */}
      </Container>
    </Box>
  );
}
