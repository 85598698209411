import * as React from 'react';
import withRoot from '../withRoot';
import {
  Box,
  Grid,
  TextField
} from '@mui/material';
import Button from '../components/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../environment';
import { toast } from 'react-hot-toast';

function KeepMeUpdatedForm({ submitAction, text }) {
  return (
    <>
      {text && <Box alignItems="center" justifyContent="center" textAlign="center" sx={{ my: 5}}>
        Please enter your details below to sign up to our mailing list to be informed of updates on the project and notification of registration openings.
      </Box>}
      <Formik
        initialValues={{
          firstName: '',
          email: ''
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        })}
        onSubmit={async (values, { resetForm, setSubmitting, setErrors, setStatus }) => {
          try {
            await addDoc(collection(db, 'kmu'), {
              firstName: values.firstName,
              email: values.email,
              registered: serverTimestamp()
            });
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            submitAction(true);
            toast.success('You have successfully registered');
          } catch (err) {
            console.log('Error:', err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            submitAction(false);
            toast.error('Something went wrong');
          }
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, setFieldValue, errors, touched, values, isSubmitting}) => (
          <form onSubmit={handleSubmit} >
            <Grid container spacing={2} sx={{ py: 1 }}>
                <Grid item xs={12} sm={4} >
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    label="First Name"
                    name="firstName"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    label="Email"
                    name="email"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 , p: 1, pb: .2 }}
                disabled={isSubmitting}
                color="secondary"
                size="large"
                variant="contained"
                fullWidth
              >
                Keep Me Updated
              </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default withRoot(KeepMeUpdatedForm);
