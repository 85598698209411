import * as React from 'react';
import {
  Box,
  Container
} from '@mui/material';
import Typography from '../components/Typography';


function Video() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Typography variant="h2" marked="center" component="h2" sx={{ mb: 5 }}>
          Example Video
        </Typography>
        <>
        <Box alignItems="center" justifyContent="center" textAlign="center" sx={{mb:4}}>

        The Velograph device does not include any video recording systems. To demonstrate how passing events are detected, two sports video cameras were attached to a bicycle (one recording the front view and one recording the side view) along with a Velograph device. The processed data from the Velograph device was then synchronised with the two cameras’ video data to produce the ‘visualisation’ example video shown below.
        <br /><br />The two distance sensors on the Velograph device each record the distance to any object passing the right side of the cyclist 50 times a second. This is shown in the graph at the bottom of the video. The red line shows distances detected by the sensor mounted at the front of the bicycle and the yellow line shows distances detected by the sensor mounted towards the rear. Sections where the line from both sensors overlap are shown in orange. Because only vehicles in the adjacent lane are of interest, any distances beyond 3 metres are ignored.
        <br /><br />An algorithm is used to automatically identify periods in the data stream where a vehicle is detected to be passing. This is achieved by observing a particular pattern in which the rear sensor detects an object (vehicle approaching cyclist from behind), then both sensors detecting an object (vehicle next to the cyclist), and finally the front sensor detecting an object (vehicle completing the pass). Such events are shown with a green coloured bar at the base of the graph in the video below. Note that periods where the cyclist is passing stationary vehicles are not detected as passing events.
        <br /><br />The average distance during any detected passing events is recorded (and adjusted to account for the width of the bicycle handlebars) along with the GPS location.
        </Box>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/JXUuAPGnkhE`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        </>
      </Container>
    </Box>
  );
}

export default Video;
