import * as React from 'react';
import {
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Link
} from '@mui/material';
import Typography from '../components/Typography';
import casrLogo from '../../static/casr-logo.png';
import bnLogo from '../../static/bn-logo.png';
import ivaiLogo from '../../static/ivai-logo.png'
import ppLogo from '../../static/pp-logo.png'
import gmLogo from '../../static/gm-logo.png';

const sXimg = {
  px: 2,
  filter: 'grayscale(100%)',
  WebkitFilter: 'grayscale(100%)',
  '&:hover': {
    filter: 'none',
    WebkitFilter: 'grayscale(0%)',
  },
  transition: '0.5s',
}

function WhosInvolved() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      <Typography variant="h2" marked="center" component="h2" sx={{ mb: 5 }}>
        Who is involved
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <ImageList
            cols={1}
          > 
            <Link href="https://ecms.adelaide.edu.au/centre-for-automotive-safety-research/">
              <ImageListItem sx={sXimg}>
                <img
                  src={casrLogo}
                  alt="casr-logo"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ImageList
            cols={1}
          > 
            <Link href="https://www.ivai.com.au">
              <ImageListItem sx={sXimg}>
                <img
                  src={ivaiLogo}
                  alt="ivai-logo"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ImageList
            cols={1}
          > 
            <Link href="https://www.bicyclenetwork.com.au">
              <ImageListItem sx={sXimg}>
                <img
                  src={bnLogo}
                  alt="bn-logo"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ImageList
            cols={1}
          > 
            <Link href="https://www.pedalpower.org.au/">
              <ImageListItem sx={sXimg}>
                <img
                  src={ppLogo}
                  alt="pp-logo"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageList
            cols={1}
          > 
          <Typography variant="h6" component="h6" align="center">
            Prize Sponsor
          </Typography>
            <Link href="https://giantmelbourne.com.au/">
              <ImageListItem sx={sXimg}>
                <img
                  src={gmLogo}
                  alt="gm-logo"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={4}>
          
        </Grid>
      </Grid>      
    </Container>
  );
}

export default WhosInvolved;
