import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Wrapper } from "@googlemaps/react-wrapper";
import { getDocs, collection } from "firebase/firestore";
import { db, GOOGLE_MAPS_API_KEY } from '../../environment';
import { Typography } from '@mui/material';
import withRoot from '../withRoot';


React.useLayoutEffect = React.useEffect; // To suppress warrning related to useing ReactDOMServer see https://stackoverflow.com/questions/58070996/how-to-fix-the-warning-uselayouteffect-does-nothing-on-the-server

const render = (status) => {
  return React.createElement("h1", null, status);
};

const numberToHexColor = (value) => {

  const interpolate = (start, end, value) => {
    return start + (end - start) * value;
  }
  
  const componentToHex = (component) => {
    var hex = component.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  // Specify the three colors for the endpoints and the middle
  var color1 = [240, 67, 38];    // Red #F04326
  var color2 = [230, 198, 85];  // Yellow #E6C655
  var color3 = [65, 193, 65];    // Green #41C141

  // Calculate the normalized value between 0 and 1
  var normalizedValue = Math.min(Math.max(value-100, 0), 100) / 100;

  // Calculate the RGB values based on the gradient
  var red, green, blue;
  if (normalizedValue <= 0.5) {
    red = Math.round(interpolate(color1[0], color2[0], normalizedValue * 2));
    green = Math.round(interpolate(color1[1], color2[1], normalizedValue * 2));
    blue = Math.round(interpolate(color1[2], color2[2], normalizedValue * 2));
  } else {
    red = Math.round(interpolate(color2[0], color3[0], (normalizedValue - 0.5) * 2));
    green = Math.round(interpolate(color2[1], color3[1], (normalizedValue - 0.5) * 2));
    blue = Math.round(interpolate(color2[2], color3[2], (normalizedValue - 0.5) * 2));
  }

  // Convert the RGB values to a hex color string
  var hexColor = "#" + componentToHex(red) + componentToHex(green) + componentToHex(blue);
  return hexColor;
}


function MyMapComponent() {
  const ref = useRef();
  const [map, setMap] = useState(); // map
  const [bounds, setBounds] = useState(); // initial bounds
  const [zoom, setZoom] = useState(7); // initial zoom
  const [center, setCenter] = useState({  // initial centre location
    lat: -36.379124, 
    lng: 144.436849
  });

  const [roads, setRoads] = useState([]);

  useEffect(() => {
    if (!map) {
      const mapT = new google.maps.Map(document.getElementById("map"), {
        zoom,
        center,
        onIdle: onIdle,
        mapId: "b8355a864294ff12",
        streetViewControl: false,
        mapTypeControl: false,
        style: { flexGrow: "1", height: "100%"},
      });
      setMap(mapT);
    }
  }, []);

  useEffect(async () => {
    const querySnapshot = await getDocs(collection(db, "roads"));
    const roadsData = querySnapshot.docs.map((doc) => {return { ...doc.data(), id: doc.id}}).filter((road) => road.num_passes > 1);
    setRoads(roadsData)
  }, []);

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
    setBounds(m.getBounds());
  };

  const InfoWindowContent = ({name, distances, average, minimum, numPasses, numTrips, numUsers}) => {
    return (
      <>
        <Typography variant="h6">{name}</Typography>
        {/* <Typography variant="body1"></Typography> */}
        {average && average > 1 && <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b>Mean Distance:</b> {average.toFixed(0)} cm</Typography>}
        {minimum && minimum > 1 && <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b>Min Distance:</b> {minimum.toFixed(0)} cm</Typography>}
        {numPasses && <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b># of Passes:</b> {numPasses}</Typography>}
        {numTrips && <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b># of Trips:</b> {numTrips}</Typography>}
        {numUsers && <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b># of Participants:</b> {numUsers}</Typography>}
        {/* {distances.length > 30 && <Histogram data={distances} />} */}
      </>
    );
  };

  const InfoWindowContentWrapped = withRoot(InfoWindowContent);

  if (roads) {
    roads.forEach((road, indexOuter) => {
      if ("encoded_poly_one" in road && !road.id.includes("Unnamed") && road.distance_one > 0 && road.num_users_one > 1) { // && "num_users_one" in road && road.num_users_one > 1 

        const line = new google.maps.Polyline({
          map,
          path: google.maps.geometry.encoding.decodePath(road.encoded_poly_one),
          strokeOpacity: 0.8 ,
          strokeWeight: 4,
          strokeColor: numberToHexColor(road.distance_one),
          key: road.id + '_one',
          extra: road
        });

        const infowindow = new google.maps.InfoWindow({
          content: ReactDOMServer.renderToString(<InfoWindowContentWrapped name={road.id.split("_").slice(1).join(" ")} distances={road.distances_one} average={road.distance_one} minimum={road.min_distance_one} numPasses={road.num_passes_one} numTrips={road.num_trips_one} numUsers={road.num_users_one}/>)
        });

        // line.addListener("mouseover", (event) => {
        line.addListener("click", (event) => {
          infowindow.setPosition(event.latLng);
          infowindow.open({map});
        });

        line.addListener('mouseout', () => {
          infowindow.close();
        });
      }
    

      if ("encoded_poly_two" in road && !road.id.includes("Unnamed") && road.distance_two > 0 && road.num_users_two > 1) { // && "num_users_two" in road && road.num_users_two > 1

        const line = new google.maps.Polyline({
          map,
          path: google.maps.geometry.encoding.decodePath(road.encoded_poly_two),
          strokeOpacity: 0.8 ,
          strokeWeight: 4,
          strokeColor: numberToHexColor(road.distance_two),
          key: road.id + '_two',
        });

        const infowindow = new google.maps.InfoWindow({
          content: ReactDOMServer.renderToString(<InfoWindowContentWrapped name={road.id.split("_").slice(1).join(" ")} distances={road.distances_two} average={road.distance_two} minimum={road.min_distance_two} numPasses={road.num_passes_two} numTrips={road.num_trips_two} numUsers={road.num_users_two}/>)
        });

        // line.addListener("mouseover", (event) => {
        line.addListener("click", (event) => {
          infowindow.setPosition(event.latLng);
          infowindow.open({map});
        });

        line.addListener('mouseout', () => {
          infowindow.close();
        });
      }
    })
  }

  return <div ref={ref} id="map" style={{display:"flex", height:"100%"}} />;
}

const PublicMap = () => {
  return (
    <Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={render} libraries={['geometry']}>
      <MyMapComponent />
    </Wrapper>
  )
};

export default PublicMap;