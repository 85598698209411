
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
  apiKey: 'AIzaSyDL7O8MJwcENS9pcgJu_R607EjboWWrBnE',
  authDomain: 'velograph-14d85.firebaseapp.com',
  projectId: 'velograph-14d85',
  storageBucket: 'velograph-14d85.appspot.com',
  messagingSenderId: '709389501755',
  appId: '1:709389501755:web:a467e33d6cf60fe28da31e',
  measurementId: 'G-GJCM3ELQV3'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'australia-southeast1');
const analytics = getAnalytics(app);

const GOOGLE_MAPS_API_KEY = "AIzaSyDL7O8MJwcENS9pcgJu_R607EjboWWrBnE";

// if using emulator
//if (window.location.hostname === 'localhost') {
//  connectFirestoreEmulator(db, 'localhost', 8080);
//}

export { db, auth, storage, functions, analytics, GOOGLE_MAPS_API_KEY };