import * as React from "react";
import withRoot from "../withRoot";
import { toast } from "react-hot-toast";
import EligibilitySignUp from "./EligibilitySignUp";
import EligibilityQuestions from "./EligibilityQuestions";
import EligibilityNope from "./EligibilityNope";
import EligibilityInfo from "./EligibilityInfo";
import EligibilityInfoTwo from "./EligibilityInfoTwo";
import EligibilityClosed from "./EligibilityClosed";

function Eligibility({ handleClose }) {
  const [page, setPage] = React.useState(4);
  const [kms, setKms] = React.useState(0);
  const [location, setLocation] = React.useState("Other");

  const submitAction = (success) => {
    switch (page) {
      case 0:
        success ? setPage(1) : handleClose();
        break;
      case 1:
        success ? setPage(2) : handleClose();
        break;
      case 2:
        success ? setPage(3) : setPage(-1);
        break;
      case 3:
        success
          ? toast.success("You have successfully registered")
          : toast.error("Something went wrong");
        handleClose();
        break;
      case 4:
        handleClose();
        break;
      case -1:
        handleClose();
        break;
      default:
        handleClose();
        break;
    }
  };

  switch (page) {
    case 0:
      return <EligibilityInfo submitAction={submitAction} />;
    case 1:
      return <EligibilityInfoTwo submitAction={submitAction} />;
    case 2:
      return (
        <EligibilityQuestions
          submitAction={submitAction}
          setKms={setKms}
          setLocation={setLocation}
        />
      );
    case 3:
      return (
        <EligibilitySignUp
          submitAction={submitAction}
          kms={kms}
          location={location}
        />
      );
    case -1:
      return <EligibilityNope submitAction={submitAction} />;
    case 4:
      return <EligibilityClosed submitAction={submitAction} />;
    default:
      return <></>;
  }
}

export default withRoot(Eligibility);
