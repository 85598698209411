import * as React from 'react';
import {
  Box,
  Dialog,
  DialogContent
} from '@mui/material';
import Typography from '../components/Typography';
import Button from '../components/Button';
import withRoot from '../withRoot';
import Eligibility from './Eligibility';

function Register() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen('body')} color="secondary" size="large" variant="contained">Register</Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" fullWidth maxWidth="lg">
        <DialogContent dividers={scroll === 'paper'}>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Registration
            </Typography>
          </Box>
          <Eligibility handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withRoot(Register);
