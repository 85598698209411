import * as React from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Dialog,
  DialogContent,
  FormHelperText
} from '@mui/material';
import Typography from '../components/Typography';
import Button from '../components/Button';
import withRoot from '../withRoot';

function Maintenance() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen('body')} color="secondary" size="large" variant="outlined" sx={{color: '#fe6700'}}>
          Login
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" fullWidth maxWidth="sm">
        <DialogContent dividers={scroll === 'paper'}>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Login
            </Typography>
          </Box>
          
          <FormHelperText>
            Dashboard currently unavailable due to maintenance. Please check back later.
          </FormHelperText>
        </DialogContent>
      </Dialog>
    </>
    
  );
}

export default withRoot(Maintenance);
