import * as React from 'react';
import {
  Box,
  List,
  Typography,
  CircularProgress,
  Stack
} from '@mui/material';
import Upload from './Upload';
import DetailsDialog from './DetailsDialog';
import { useRecoilState } from 'recoil';
import { authState, tripsState, selectedState } from '../../store';
import { doc, deleteDoc, getDoc, updateDoc, onSnapshot, collection, getDocs } from "firebase/firestore";
import toast from 'react-hot-toast';
import { db } from '../../environment';
import Trip from './Trip';
import TripTotals from './TripTotals';
import Button from '../components/Button';

const TripBar = () => {
  
  const [detailsOk, setDetailsOk] = React.useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [userData, setUserData] = React.useState(false);
  const [failedFiles, setFailedFiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [refreshTrips, setRefreshTrips] = React.useState(true);

  const [authS, setAuthS] = useRecoilState(authState);
  const [trips, setTrips] = useRecoilState(tripsState);
  const [selected, setSelected] = useRecoilState(selectedState);

  const [userFiles, setUserFiles] = React.useState([]);
  const [processingFiles, setProcessingFiles] = React.useState([]);
  
  React.useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", authS.user), async (docSnap) => {
      const userDataTemp = docSnap.data();
      setUserData(userDataTemp);
      if ('age' in userDataTemp && 'gender' in userDataTemp && 'typebike' in userDataTemp && 'typepedals' in userDataTemp && 'senslong' in userDataTemp && 'sensheight' in userDataTemp && 'senslat' in userDataTemp && 'handlebar' in userDataTemp) {
        setDetailsOk(true);
      }
    });

    return unsub;
  }, []);

  React.useEffect(() => {
    const unsub = onSnapshot(collection(db, "users", authS.user, "files"), async (querySnapshot) => {
      const userFiles = [];
      const processingFiles = [];
      setUserFiles(querySnapshot.forEach((doc) => {
        userFiles.push(doc.data());
        if (doc.data().status === 'Processing' || doc.data().status === 'Merging') {
          processingFiles.push({...doc.data(), id: doc.id});
        }
      }));
      setUserFiles(userFiles);
      setProcessingFiles(processingFiles);
      
      // querySnapshot.docChanges().forEach((change) => {
      //   console.log(change.doc.data());
      // }
      // );
    })
    return unsub;
  }
  , []);

  React.useEffect(() => {
    if (processingFiles.length == 0) {
      setRefreshTrips(true);
    }
  }, [processingFiles]);

  const deleteTrip = async (index) => {
    setSelected(0);
    const trip = trips[index];
    const name = trip.name;
    setIsLoading(true);
    try {
      const userDoc = await getDoc(doc(db, "users", authS.user));
      const curTrips = userDoc.data().trips;
      const curSubtrips = userDoc.data().subtrips;
      const newTrips = curTrips.filter((tRef) => tRef.id != trip.id);
      const newSubtrips = curSubtrips.filter((stRef) => !trip.subtrips.includes(stRef.id));

      await Promise.all(trip.passes.map(async (pass) => {
        if (("road" in pass) && (pass["road"] !== undefined)) {
          await deleteDoc(doc(db, "roads", pass.road, "passes", pass.id));
        }
        await deleteDoc(doc(db, "passes", pass.id));
      }));

      await Promise.all(trip.subtrips.map(async (subtripId) => {
        await deleteDoc(doc(db, "subtrips", subtripId));
      }));

      await deleteDoc(doc(db, "trips", trip.id));

      await updateDoc(doc(db, "users", authS.user), {
        trips: newTrips,
        subtrips: newSubtrips
      });
      
      toast.success(name + ' Deleted');
      setIsLoading(false);
      setTrips((oldTrips) => removeItemAtIndex(oldTrips, index));

    } catch(e) {
      console.log(e);
      toast.error('Error Deleting ' + name);
      setIsLoading(false);
    }
  };

  const removeItemAtIndex = (arr, index) => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }

  const updateTrips = async () => {
    setIsLoading(true);
    const docSnap = await getDoc(doc(db, "users", authS.user));
    const failed_files = docSnap.data().failed_files;
    const tripsData = docSnap.data().trips;
    setFailedFiles(failed_files);

    const newTrips = await Promise.all(tripsData.map(async (tripRef) => {
      try {
        const tripDoc = await getDoc(tripRef);
        const trip = tripDoc.data();
        trip.subtrips = trip.subtrips.map(ref => ref.id);  // recoil doesn't like docRefs it thinks they are mutable
        trip.id = tripRef.id;
        trip.loaded = false;
        trip.passes = [];
        trip.points = [];
        trip.temps = [];
        trip.user = trip.user.id;
        return trip
      } catch(e) {
        // await updateDoc(doc(db, "users", authS.user), {trips: arrayRemove(tripRef) });
        console.log(JSON.stringify(e), tripRef.path);
      }
    }));
    newTrips.sort((a, b) => (a.start_time.toDate() > b.start_time.toDate()) ? -1 : 1);
    
    if (trips.length < newTrips.length) {
      if (trips.length > 0) {  // new trip must have just been added
        setSelected(newTrips.length-1);  // change the selected to the most recently uploaded one
      } else {
        setSelected(0);  // first get of trips
      }
    } 

    if (newTrips.length == 0) {
      setSelected(-2);
    } 

    setTrips(newTrips);
    setIsLoading(false);
  }

  React.useEffect(async () => {
    if (refreshTrips) {
      await updateTrips();
      setRefreshTrips(false);
    }
  }, [refreshTrips]);

  return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          alignContent: 'stretch',
          height: '100%',
        }}
      >
        <Box
          sx={{
            p: 2,
          }}
        >
          {detailsOk ?
          (processingFiles.length > 0 ?
            <Stack direction={"row"} spacing={1} sx={{ width: '100%', display: 'flex', justifyContent: "center",  borderRadius: 1, border: 0, background: 'rgb(240, 197, 168)', p: 1.5, pb: .6  }}>

              <CircularProgress size={21} />
            <Typography variant="h6" align="center">Processing {processingFiles.length} files</Typography>

              
            </Stack>
            :
            <Upload setIsLoading={setIsLoading} setRefreshTrips={setRefreshTrips} />)
          : 
          <>
            <Button onClick={() => setDetailsDialogOpen(true)} color="secondary" size="large" variant="contained" fullWidth>New Trip</Button>
            <DetailsDialog detailsDialogOpen={detailsDialogOpen} setDetailsDialogOpen={setDetailsDialogOpen} first={true}/>
          </>
        }
        </Box>
        <Box 
          sx={{
            display: "flex",
            flex: "1 1 auto"
          }}
        >
          <List sx={{
            width: 540,
            overflow: 'auto',
            maxHeight: 'calc(100vh - 194px)',
            py: 0
          }}>
          {(isLoading || selected == -1) &&
            <Box sx={{ width: '100%', display: 'flex', justifyContent: "center", p: 2 }}>
              <CircularProgress />
            </Box>
          }
          {!isLoading && selected > -1 && trips && trips.length && trips.map((trip) =>
            <Trip key={trip.id} trip={trip} deleteTrip={deleteTrip} />
          )}
          {!isLoading && selected == -2 &&
            <>
              <Typography variant="subtitle1" align="center">No trips uploaded</Typography>
            </>
          }
          </List>
        </Box>
        <TripTotals failedFiles={failedFiles} />
      </Box>
  );
}

export default TripBar;
