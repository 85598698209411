import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  ListItem,
  Divider,
  Stack,
  TextField,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import Button from '../components/Button';
import { collection, doc, deleteDoc, getDoc, updateDoc, arrayUnion, arrayRemove, onSnapshot } from "firebase/firestore";
import { db } from '../../environment';
import { useRecoilState } from 'recoil';
import { authState, tripsState, selectedState, hoveredState } from '../../store';

const Trip = ({ trip, deleteTrip }) => {
  
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState('');
  const [renameDialogOpen, setRenameDialogOpen] = React.useState('');

  const [authS, setAuthS] = useRecoilState(authState);
  const [trips, setTrips] = useRecoilState(tripsState);
  const [selected, setSelected] = useRecoilState(selectedState);
  const [hovered, setHovered] = useRecoilState(hoveredState);
  const index = trips.findIndex((t) => t === trip);
  
  React.useEffect(async () => {
    if (!trip.loaded && index == selected) {
      let passes = [];
      let points = [];
      let temps = [];
      await Promise.all(trip.subtrips.map(async subtripId => {
        const subtripDoc = await getDoc(doc(collection(db, "subtrips"), subtripId));
        const subtrip = subtripDoc.data();
        if (!subtrip) {
          return;
        }
        points.push(...subtrip.points);
        const passesData = await Promise.all(subtrip.passes.map(async (passRef) => {
          const pass = await getDoc(passRef);
          const passData = pass.data();
          if ("road" in passData) {
            return {
              ...passData,
              id: passRef.id,
              subtrip: passData.subtrip.id,
              user: passData.user.id,
              road: passData.road.id
            };
          }
          return {
            ...passData,
            id: passRef.id,
            subtrip: passData.subtrip.id,
            user: passData.user.id
          };
        }));
        passes.push(...passesData);
        if ("temps" in subtrip) {
          temps.push(...subtrip.temps);
        }
      }));

      setTrips((oldTrips) => replaceItemAtIndex(oldTrips, index, {
        ...trip,
        passes,
        points,
        temps,
        loaded: true,
      }));
    }
  }, [selected]);


  const changeSelected = (i) => {
    setSelected(i);
  }

  const changeHovered = (i) => {
    setHovered(i);
  }

  const renameTrip = async (trip, name) => {
    setTrips((oldTrips) => replaceItemAtIndex(oldTrips, index, {
      ...oldTrips[index],
      loading: true,
    }));
    const tripRef = doc(db, "trips", trip.id);
    await updateDoc(tripRef, {
      name: name
    }).then(() => {
      setTrips((oldTrips) => replaceItemAtIndex(oldTrips, index, {
        ...oldTrips[index],
        name: name,
        loading: false,
      }));
      toast.success('Trip Renamed');
    }).catch((e) => {
      console.log(e);
      toast.error('Rename Failed');
    });
  };

  const replaceItemAtIndex = (arr, index, newValue) => {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
  }
  const replaceItemWithId = (arr, id, newValue) => {
    const index = trips.findIndex((t) => t.id === id);
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
  }

  return (
    <ListItem button sx={{ pt: 1, pb: 0, pl: 2, pr: 1 }} key={trip.id} onClick={() => changeSelected(index)} onMouseEnter={() => changeHovered(index)} onMouseLeave={() => changeHovered(-1)} selected={index == selected}> 
      <Box
        sx={{ display: 'flex', width: '100%' }}
      >
        <Stack sx={{ flexGrow: 1 }}>
            {"name" in trip ?
              index == selected ?
                <Typography variant='h6' color="secondary">
                  {trip.name}
                </Typography>
              :
                <Typography variant='h6' >
                  {trip.name}
                </Typography>
            :
            <Typography variant='h6' >
              Loading
            </Typography>
            }
          <Box
              sx={{
                display: 'flex',
              }}
            >              
              {"start_time" in trip && 
                <Typography variant="subtitle1" display="block" sx={{mr: 1}} gutterBottom>
                  {trip.start_time.toDate().toLocaleDateString('en-AU') + ' ' + trip.start_time.toDate().toLocaleTimeString()}
                </Typography>
              }
              {"distance" in trip && 
                <>
                  <Divider orientation="vertical" flexItem sx={{mt:0.5, mb: 1.2}} />
                  <Typography variant="subtitle1" display="block" sx={{mx: 1}} gutterBottom>
                    {trip.distance.toFixed(1)}km
                  </Typography>
                </>
              }
              {"num_passes" in trip &&
              <>
                <Divider orientation="vertical" flexItem sx={{mt:0.5, mb: 1.2}} />
                <Typography variant="subtitle1" display="block" sx={{mx: 1}} gutterBottom>
                  {trip.num_passes} Passes
                </Typography>
              </>
              }
              {"avg_temp" in trip && trip.avg_temp &&
                <>
                  <Divider orientation="vertical" flexItem sx={{mt:0.5, mb: 1.2}} />
                  <Typography variant="subtitle1" display="block" sx={{mx: 1}} gutterBottom>
                  {trip.avg_temp.toFixed(1)}°C
                  </Typography>
                </>
              }
              {trip.subtrips.length > 1 &&
                <>
                  <Divider orientation="vertical" flexItem sx={{mt:0.5, mb: 1.2}} />
                  <Typography variant="subtitle1" display="block" sx={{mx: 1}} gutterBottom>
                    {trip.subtrips.length} Files
                  </Typography>
                </>
              }
            </Box>
        </Stack>
        { !trip.loading &&
          <>
            <Box sx={{ mr: 0 }}>
              <Tooltip title="Rename">
                <IconButton onClick={() => setRenameDialogOpen(trip.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Dialog
                open={renameDialogOpen == trip.id}
                onClose={() => setRenameDialogOpen('')}
                fullWidth
                maxWidth='xs'
              >
                  <Formik
                    initialValues={{
                      name: trip.name,
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string()
                        .min(1, 'New Name Too Short')
                        .max(35, 'New Name Too Long')
                    })}
                    onSubmit={async (values, { resetForm, setSubmitting, setErrors, setStatus }) => {
                      try {
                        await renameTrip(trip, values.name);
                        resetForm();
                        setStatus({ success: true });
                        setSubmitting(false);
                        setRenameDialogOpen('');
                      } catch (err) {
                        console.log('Error:', err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({ handleBlur, handleChange, handleSubmit, setFieldValue, errors, touched, values, isSubmitting}) => (
                      <form onSubmit={handleSubmit} >
                        <DialogContent>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            label="New Name"
                            name="name"
                            fullWidth
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setRenameDialogOpen('')}>Cancel</Button>
                          <Button 
                            type="submit"
                            disabled={isSubmitting}
                            color="secondary"
                            variant="contained">
                            Rename
                          </Button>
                        </DialogActions>
                      </form>
                    )}
                  </Formik>
              </Dialog>
            </Box>
            <Box sx={{ mr: 0 }}>
              <Tooltip title="Delete">
                <IconButton onClick={() => setDeleteDialogOpen(trip.id)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Dialog
                open={deleteDialogOpen == trip.id}
                onClose={() => setDeleteDialogOpen('')}
              >
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete {trip.name}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteDialogOpen('')} variant="contained">No</Button>
                  <Button onClick={() => {
                    setDeleteDialogOpen('');
                    deleteTrip(index);
                  }} color="secondary" variant="contained">
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </>
        }
      </Box>
    </ListItem>
  );
}

export default Trip;
