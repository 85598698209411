import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  Divider,
  Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Button from '../components/Button';
import { tripsState } from '../../store';
import { useRecoilState } from 'recoil';

const TripTotals = ({ failedFiles }) => {
  
  const [totalDistance, setTotalDistance] = React.useState(0);
  const [totalPasses, setTotalPasses] = React.useState(0);
  const [failsDialogOpen, setFailsDialogOpen] = React.useState(false);
  const [trips, setTrips] = useRecoilState(tripsState);


  React.useEffect(() => {  
    let totDistance = 0;  
    let totPasses = 0; 
    if (trips.length) {
      totDistance = trips.map(t => t.distance).reduce((partialSum, a) => partialSum + a, 0);
      totPasses = trips.map(t => t.num_passes).reduce((partialSum, a) => partialSum + a, 0);

      setTotalDistance(totDistance);
      setTotalPasses(totPasses);
    }
  }, [trips]);
  

  return (
      <Box
        sx={{
          px: 2,
          py: 0,
          display: 'flex',
          bgcolor: '#ffe1cc',
          width: '100%'
        }}
      >
        <Typography variant="subtitle1" display="block" sx={{mr: 1, mt:1.5, fontWeight: 'bold'}} gutterBottom>
          {trips.length} trips
        </Typography>
        <Divider orientation="vertical" flexItem sx={{mt:1, mb: 1.2}} />
        
        <Typography variant="subtitle1" display="block" sx={{mx: 1, mt:1.5, fontWeight: 'bold'}} gutterBottom>
          {totalDistance.toFixed(1)}km
        </Typography>
        <Divider orientation="vertical" flexItem sx={{mt:1, mb: 1.2}} />
        
        <Typography variant="subtitle1" display="block" sx={{mx: 1, mt:1.5, fontWeight: 'bold'}} gutterBottom>
          {totalPasses} passes
        </Typography>

        <Divider orientation="vertical" flexItem sx={{mt:1, mb: 1.2}} />
        {failedFiles && failedFiles.length > 0 ?
            <>
              <Typography variant="subtitle1" display="block" sx={{mx: 1, mt:1.5, fontWeight: 'bold'}} gutterBottom>
              {failedFiles.length} failed uploads
              </Typography>
            </>
          :
            <Typography variant="subtitle1" display="block" sx={{mx: 1, mt:1.5, fontWeight: 'bold'}} gutterBottom>
              No failed uploads
            </Typography>
          }
        <Tooltip title="More Info">
          <IconButton size="small" onClick={() => setFailsDialogOpen(true)} sx={{pt: 1.3, pb: 1.5}}>
            <InfoIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
        <Dialog
          fullWidth
          maxWidth="md"
          open={failsDialogOpen}
          onClose={() => setFailsDialogOpen(false)}
        >
          <DialogContent>
            <Typography variant="h6">
              Failed Uploads
            </Typography>
            <DialogContentText>
              {failedFiles && failedFiles.length > 0 && failedFiles.map(({filename, reason}) => {
                return <Box
                    key={filename}
                    sx={{
                      pt: .5,
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      borderTop: 1,
                      borderTopColor: '#EEE'
                    }}
                  >
                    
                  <Typography variant="subtitle1" display="block" sx={{m: 0, fontWeight: 'bold'}} gutterBottom>
                    {filename}
                  </Typography>
                  <Typography variant="subtitle1" display="block" sx={{m: 0, fontWeight: 'bold'}} gutterBottom>
                    {reason}
                  </Typography>
                  </Box>
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFailsDialogOpen(false)} variant="contained">Close</Button>
          </DialogActions>
        </Dialog>
        {/* <Typography variant="subtitle1" display="block" sx={{mx: 1, fontWeight: 'bold', 
          color: '#fe6700',}} gutterBottom>
          download data
        </Typography> */}
      </Box>
  );
}

export default TripTotals;
