import * as React from 'react';
import DashAppBar from './DashAppBar';
import withRoot from '../withRoot';
import TripBar from './TripBar';
import {
  Box, Stack,
} from '@mui/material';
// import GGMap from './GGMap';
import PublicMap from './PublicMap';
import DashboardMap from './DashboardMap';
import Typography from '../components/Typography';

function Dashboard() {
  const [rawPoints, setRawPoints] = React.useState(false);
  const [publicView, setPublicView] = React.useState(false);
  const [allTrips, setAllTrips] = React.useState(false);
  const [allLoaded, setAllLoaded] = React.useState(true);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          p: 0,
          m: 0,
          height: '100vh',
          width: '100vw'
        }}
      >
        <DashAppBar rawPoints={rawPoints} setRawPoints={setRawPoints} publicView={publicView} setPublicView={setPublicView} allTrips={allTrips} setAllTrips={setAllTrips} allLoaded={allLoaded}/>        
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'row',
            width: '100%',
            height: '100%'
          }}
        >
          <TripBar />
          <Box sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
            {publicView ? 
            <>
              <Stack sx={{ width: '100%', height: '100%', flexGrow: 1 }}>
              <PublicMap />

              <Box sx={{width: '100%', display: 'flex', flexGrow: 1, justifyContent: 'center', textAlign: 'center', background: '#222', pt:1 }}>
                <Stack direction='row'>
                <Box sx={{pt:0.5, mr: 2}}>
                <Typography variant="subtitle1" display="block" sx={{mx: 1, color: "#999"}} gutterBottom>
                  Mean Distance:
                </Typography>
                </Box>
                <Box sx={{width: 300}}>
                  <Box sx={{height: 5, m: 0.5, borderRadius: 2, background: 'linear-gradient(to right, #F04326, #E6C655, #41C141)'}} />
                  <Stack direction='row' sx={{width: '100%', color: "#999", flexGrow: 1, justifyContent: 'space-between'}}>
                  <Typography variant="subtitle1" display="block" sx={{color: "#999"}}>1m</Typography>
                  <Typography variant="subtitle1" display="block" sx={{color: "#999"}}>1.5m</Typography>
                  <Typography variant="subtitle1" display="block" sx={{color: "#999"}}>2m</Typography>
                  </Stack>
                </Box>
                </Stack>
              </Box>
            </Stack>
            </>
            :
              <DashboardMap rawPoints={rawPoints} allTrips={allTrips} setAllLoaded={setAllLoaded}/>
              // <GGMap rawPoints={rawPoints} allTrips={allTrips} setAllLoaded={setAllLoaded}/>
            }
            </Box>
        </Box>
      </Box>
    </>
  );
}

export default withRoot(Dashboard);
