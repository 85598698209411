import * as React from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import withRoot from '../withRoot';
import Button from '../components/Button';

function EligibilityInfo({submitAction}) {

  const onSub = () => {
    submitAction(true);
  }

  return (
    <>
      <Box sx={{ mt: 3}}>
        <b>This is a research project, where your participation is completely voluntary. The following information is provided so you are fully informed about the participation in the project. If you still wish to participate after reading through this information, click the Continue button at the bottom of the page.</b>
        <br /><br />
        The Centre for Automotive Safety Research (CASR) at the University of Adelaide, is collecting naturalistic data on passing distances using a bicycle-mounted device that measures the distance between cyclists and road vehicles.
        <br /><br />
        To be eligible to participate in this project you need to be a regular bicycle rider who rides predominantly on the road and stores their bicycle in a secure location. You should be comfortable with using technology, and you need to be willing to have a small electronic device  mounted on your bicycle for a period of up to four weeks. Unfortunately, participation is not open to people under the age of 18. If you meet these criteria you may be eligible to participate in this study.
        <br /><br />
        The table below lists the current status of recruitment in various data collection locations.
        <br />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>Time Period</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="Adelaide" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell component="th" scope="row">Adelaide</TableCell>
                <TableCell>Current to January 2024</TableCell>
                <TableCell sx={{color: 'green', fontWeight: 'bold'}}>OPEN</TableCell>
              </TableRow>
              <TableRow key="Canberra" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell component="th" scope="row">Canberra</TableCell>
                <TableCell>Current to January 2024</TableCell>
                <TableCell sx={{color: 'green', fontWeight: 'bold'}}>OPEN</TableCell>
              </TableRow>
              <TableRow key="Melbourne" sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell component="th" scope="row">Melbourne</TableCell>
                <TableCell>Until June 2023</TableCell>
                <TableCell sx={{color: 'red', fontWeight: 'bold'}}>CLOSED</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        If you are eligible to participate, the study will involve mounting a small device on your bicycle that will automatically measure vehicle passing distances, your riding speed and your location. Some devices may also collect data on environmental temperature. You will be asked to collect data for a period of up to four weeks. The data collected by the device will be logged internally and there will be no visual display of passing distance or any other data. There will be no requirement for participants to adjust the device. However, you will need to periodically plug the device into a computer to recharge the battery and upload your collected data to the project website. Instructions will be provided on how to do this.
        <br /><br />
        The device will not affect your bicycle or interfere with your normal riding in any way. Additionally, the study will not require you to change your riding patterns at all; the idea is that you ride your bicycle in your usual manner. If you own more than one bicycle, the device will be mounted to the bicycle you use most frequently. 
        <br /><br />
        Participants will be asked to switch the device on at the start of their journeys and the switch the device off at the end of their journeys. You will be able to leave the device switched off if you do not wish to record a particular journey (or portion of a journey).
      </Box>
      <Box>
        <form onSubmit={onSub}>
          <Button
            type="submit"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
            size="large"
            variant="contained"
            fullWidth
            onClick={submitAction}
          >
            Continue
          </Button>
        </form>
      </Box>
    </>
  )
}

export default withRoot(EligibilityInfo);
