import * as React from 'react';
import WhosInvolved from './WhosInvolved';
import AppFooter from './AppFooter';
import ProductHero from './ProductHero';
import HowItWorks from './HowItWorks';
import AppAppBar from './AppAppBar';
import withRoot from '../withRoot';
import About from './About';
import Publications from './Publications';
import TheDevice from './TheDevice';
import PublicMapWrap from './PublicMapWrap';
import Video from './Video';
import Stats from './Stats';

function Index() {
  return (
    <>
      <AppAppBar sx={{
        minHeight: {
          sm: '56px',
          xs: '118px'
        }
      }} />
      <ProductHero />
      <About />
      <HowItWorks />
      <Stats />
      <PublicMapWrap />
      <TheDevice />
      <Video />
      <Publications />
      <WhosInvolved />
      <AppFooter />
    </>
  );
}

export default withRoot(Index);
