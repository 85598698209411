import React from 'react';
import { RecoilRoot } from 'recoil'
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import './index.css';
import Home from './modules/views/Home';
import Dashboard from './modules/views/Dashboard';
import PrivateRoute from './modules/components/PrivateRoute';


ReactDOM.render(
  
  <React.StrictMode>
    <Toaster position="top-center"></Toaster>
    <RecoilRoot>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <Route path="*">
            404
          </Route>
        </Switch>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  
  document.getElementById('root')
  
);