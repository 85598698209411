import * as React from 'react';
import {
  Box,
  Grid,
  Stack
} from '@mui/material';
import withRoot from '../withRoot';
import Button from '../components/Button';
import ConsentForm from '../../static/ConsentForm.pdf';
import ParticipantInfo from '../../static/ParticipantInformationSheet.pdf';

function EligibilityInfoTwo({submitAction}) {

  const onSub = () => {
    submitAction(true);
  }

  return (
    <>
      <Box sx={{ mt: 3}}>
        <b>What will I need to do?</b>
        <br /><br />
        Prior to participation you will be contacted by a researcher, or an authorised delegate from one of the project partners, to arrange a suitable date for you to attend a fitting session. You will need to bring your bicycle to the fitting session and all aspects of the project will be explained. You will have an opportunity to ask any questions you may have and asked to sign a consent form. A data collection device will then be provided to you, and you will be guided through the process of attaching the device to your bicycle. You will also be provided with a link to the project website and a code that will enable you to log in as a participant.
        <br /><br />
        Once the device is installed, you will need to switch it on at the start of your rides and switch it off at the end. The data collected by the device will be logged internally and there will be no visual display of passing distance or any other data. There will be no requirement for you to adjust the device. The device should not affect your bike or interfere with your normal riding in any way. Participation does not require you to change your riding patterns at all – you should you ride your bicycle in your usual manner. If you own more than one bicycle, the device will be mounted to the bicycle you use most frequently. You will be able to turn the device off if you do not wish a particular ride, or section of a ride, to be recorded.
        <br /><br />
        During the period that you have the device, you will periodically need to recharge the device and upload any data you have collected to the project website. This will involve unclipping a section of the device and then connecting it to a computer via a USB cable. Instructions for this will have been provided during the fitting session and will also be available on the project website. You will also have the contact details of a local project support contact who can assist with any issues you may have.
        <br /><br />
        After a period of about 4 weeks, you will be contacted again by a researcher, or an authorised delegate from one of the project partners, to arrange a suitable date for you to attend a removal session. At the removal session, you will be guided through the process of removing the device and return it so that it can be used by another participant.
        <br /><br />
        All information gathered during the project will be kept completely confidential and anonymised. Participation in this project is entirely voluntary and, even if you agree to participate, you are free to withdraw at any time, noting that any data you have already uploaded will have been anonymised and thus it will not be possible to identify for deletion. Only anonymised and aggregated results will be reported in any publications that result from this project.
        <br /><br />
        This study has been approved by the University of Adelaide Human Research Ethics Committee (Approval number H-2021-049).
        <br /><br />
        If you have any questions or problems associated with the practical aspects of your participation in the project, or wish to raise a concern or complaint about the project, then you should contact the Principal Investigator, Dr Jamie Mackenzie on (08) 8313 7329 during business hours or by email to jamie.mackenzie@adelaide.edu.au. You can contact the Human Research Ethics Committee’s Secretariat on phone (08) 8313 6028 or by email to hrec@adelaide.edu.au if you wish to speak with an independent person regarding concerns or a complaint, the University’s policy on research involving human participants, or your rights as a participant. Any complaint or concern will be treated in confidence and fully investigated. You will be informed of the outcome.
        <br /><br />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <a href={ParticipantInfo} download="Participant Information Sheet.pdf" target='_blank' style={{textDecoration: 'none'}}>
              <Button
                sx={{ mt: 1, textDecoration: 'none'}}
                color="secondary"
                size="large"
                variant="contained"
                fullWidth
              >Download Participant Information Sheet</Button>
            </a>
          </Grid>
          <Grid item xs={6}>  
            <a href={ConsentForm} download="Consent Form.pdf" target='_blank' style={{textDecoration: 'none'}}>
              <Button
                sx={{ mt: 1, textDecoration: 'none'}}
                color="secondary"
                size="large"
                variant="contained"
                fullWidth
              >Download Consent Form</Button>
            </a>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <form onSubmit={onSub}>
          <Button
            type="submit"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
            size="large"
            variant="contained"
            fullWidth
            onClick={submitAction}
          >
            Check Eligibility
          </Button>
        </form>
      </Box>
    </>
  )
}

export default withRoot(EligibilityInfoTwo);
