import * as React from "react";
import { Box, Container, Grid, LinearProgress, Stack } from "@mui/material";
import Typography from "../components/Typography";
import { db } from "../../environment";
import { doc, getDoc } from "firebase/firestore";

function Stats() {
  const [stats, setStats] = React.useState({});

  React.useEffect(async () => {
    const statsSnap = await getDoc(doc(db, "stats", "general"));
    setStats(statsSnap.data());
  }, []);

  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
        <Typography
            variant="h2"
            marked="center"
            component="h2"
            sx={{ mb: 5 }}
          >
            Current Dataset
          </Typography>
      {stats.active_users ? (
        <>
          
          <Grid container spacing={1} sx={{ p: 2 }}>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block" color="secondary">
                  {stats.active_users - 17}
                </Typography>
                <Typography variant="h6" display="block">
                  Participants
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block" color="secondary">
                  {stats.total_trips - 315}
                </Typography>
                <Typography variant="h6" display="block">
                  Trips
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block" color="secondary">
                  {Math.round(stats.total_distance) - 1531}
                </Typography>
                <Typography variant="h6" display="block">
                  Kilometers Travelled
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block" color="secondary">
                  {stats.total_passes - 7362}
                </Typography>
                <Typography variant="h6" display="block">
                  Passes
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
      <LinearProgress color="secondary"/>
    </Box>
      )}
    </Container>
  );
}

export default Stats;
