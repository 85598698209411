import * as React from 'react';
import withRoot from '../withRoot';
import {
  Box,
  Grid,
  TextField
} from '@mui/material';
import Button from '../components/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../environment';

function EligibilitySignUp({ submitAction, kms, location }) {
  return (
    <>
      <Box alignItems="center" justifyContent="center" textAlign="center" sx={{ my: 5}}>
        You are eligible to participate in the project. If you would like to participate, please provide your name, email address and phone number so we can contact you.
        <br /><br />
        {location == 'Melbourne' &&
          <>
            Please note as you are in Melbourne, your contact details will be shared with our project partner Bicycle Network to organise and support your participation.
            <br />
          </>
        }
        {location == 'Canberra' &&
          <>
            Please note as you are in Canberra, your contact details will be shared with our project partner Pedal Power ACT to organise and support your participation.
            <br />
          </>
        }
        All contact details will be deleted by the project researchers and all project partners at the end of the project.
      </Box>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Required'),
          lastName: Yup.string()
            .max(20, 'Must be 20 characters or less')
            .required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          phone: Yup.string()
            .test('len', 'Must be exactly 10 characters', val => val && val.length === 10)
            .required('Required'),
        })}
        onSubmit={async (values, { resetForm, setSubmitting, setErrors, setStatus }) => {
          try {
            await addDoc(collection(db, 'registrations'), {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phone: values.phone,
              location: location,
              kms: kms,
              registered: serverTimestamp()
            });
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            submitAction(true);
          } catch (err) {
            console.log('Error:', err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            submitAction(false);
          }
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, setFieldValue, errors, touched, values, isSubmitting}) => (
          <form onSubmit={handleSubmit} >
            <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12} sm={6} >
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    label="First Name"
                    name="firstName"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    label="Last Name"
                    name="lastName"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    label="Email"
                    name="email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    label="Phone Number"
                    name="phone"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 }}
                disabled={isSubmitting}
                color="secondary"
                size="large"
                variant="contained"
                fullWidth
              >
                Register
              </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default withRoot(EligibilitySignUp);
