import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { tripsState, selectedState, hoveredState } from '../../store';
import ReactDOMServer from 'react-dom/server';
import { Wrapper } from "@googlemaps/react-wrapper";
import { getDocs, collection } from "firebase/firestore";
import { db, GOOGLE_MAPS_API_KEY } from '../../environment';
import { Typography } from '@mui/material';
import withRoot from '../withRoot';

React.useLayoutEffect = React.useEffect; // To suppress warrning related to useing ReactDOMServer see https://stackoverflow.com/questions/58070996/how-to-fix-the-warning-uselayouteffect-does-nothing-on-the-server

const SNAPPED_THRESH = 30;

const render = (status) => {
  return React.createElement("h1", null, status);
};

function MyMapComponent({ rawPoints, allTrips, setAllLoaded }) {
  const ref = useRef();
  const [map, setMap] = useState(); // map
  const [bounds, setBounds] = useState(); // initial bounds
  const [zoom, setZoom] = useState(4); // initial zoom
  const [center, setCenter] = useState({  // initial centre location
    lat: -25.410176, 
    lng: 135.879272
  });
  const [globalPasses, setGlobalPasses] = useState([]);
  const [zoomInit, setZoomInit] = useState(true);
  const [trips, setTrips] = useRecoilState(tripsState);
  const [selected, setSelected] = useRecoilState(selectedState);
  const [hovered, setHovered] = useRecoilState(hoveredState);

  const [markers, setMarkers] = useState([]); // markers
  const [lines, setLines] = useState([]); // lines

  useEffect(() => {
    if (!map) {
      const mapT = new google.maps.Map(document.getElementById("map"), {
        zoom,
        center,
        mapId: "42aa46cbcdb6a450",
        streetViewControl: false,
        style: { flexGrow: "1", height: "100%"},
      });
      google.maps.event.addListener(mapT, 'idle', () => {
        setZoom(mapT.getZoom());
        setCenter(mapT.getCenter().toJSON());
        setBounds(mapT.getBounds());
        });
      setMap(mapT);
    }
  }, []);

  useEffect(async () => {
    if (allTrips) {
      setAllLoaded(false);
      const querySnapshot = await getDocs(collection(db, "passes"));
      const locations = querySnapshot.docs.map((doc) => doc.data().location);
      setGlobalPasses(locations);
      setAllLoaded(true);
    }    
  }, [allTrips]);

  useEffect(async () => {

    if (map && trips.length && trips[selected] && "points" in trips[selected] && trips[selected].points.length) {
      let mididx = Math.floor(trips[selected].points.length/2);
      if (!trips[selected].points[mididx].location) {
        mididx = 0;
      }
      setCenter({  // initial centre location
        lat: trips[selected].points[mididx].location._lat,
        lng: trips[selected].points[mididx].location._long
      });
      map.setOptions({center: {  // initial centre location
        lat: trips[selected].points[mididx].location._lat,
        lng: trips[selected].points[mididx].location._long
      }});
      if (zoomInit) {
        setZoom(11);
        map.setOptions({zoom: 11});
        setZoomInit(false);
      }
    }
  }, [trips, selected]);

  const courseToDirection = (angle) => {
    if ((angle >= 0 && angle < 22.5) || (angle >= 337.5 && angle <= 360)) {
      return "N";
    } else if (angle >= 22.5 && angle < 67.5) {
      return "NE";
    } else if (angle >= 67.5 && angle < 112.5) {
      return "E";
    } else if (angle >= 112.5 && angle < 157.5) {
      return "SE";
    } else if (angle >= 157.5 && angle < 202.5) {
      return "S";
    } else if (angle >= 202.5 && angle < 247.5) {
      return "SW";
    } else if (angle >= 247.5 && angle < 292.5) {
      return "W";
    } else if (angle >= 292.5 && angle < 337.5) {
      return "NW";
    } else {
      return "Invalid angle";
    }
  }

  const InfoWindowContent = ({time, distance, course, speed}) => {
    return (
      <>
        <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b>Time:</b> {time}</Typography>
        <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b>Distance:</b> {distance} cm</Typography>
        <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b>Speed:</b> {speed} km/h</Typography>
        <Typography variant="subtitle1" display="block" sx={{fontSize: 16}}><b>Direction:</b> {courseToDirection(course)}</Typography>
      </>
    );
  };

  const InfoWindowContentWrapped = withRoot(InfoWindowContent);

  // get the data
  useEffect(() => {
    const markersT = []
    const linesT = []

    // show all passes
    if (allTrips && globalPasses.length) {
      globalPasses.filter((location)  => {
        return bounds.getNorthEast().lat() > location['_lat'] && location['_lat'] > bounds.getSouthWest().lat() && bounds.getNorthEast().lng() > location['_long'] && location['_long'] > bounds.getSouthWest().lng();
      }).forEach((location, i) => {
        if (i < 200) {
          const marker = new google.maps.Marker({ 
            map,
            key: i,
            position: { lat: location['_lat'], lng: location['_long'] },
            label: "",
            title: "",//Math.round(distance).toString(),
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: "#fe6700",
              fillOpacity: 0.5,
              strokeWeight: 0,
              anchor: new google.maps.Point(
                0, // width
                0 // height
              ),
              scale: 3, //Math.min(2, Math.max(.7,-0.0571429*zoom*zoom + 4*zoom-49.1429)),
              labelOrigin: new google.maps.Point(0,0)
            },
          });

          markersT.push(marker);
        }
      })
    }

    // show passes on selected trip
    if (!allTrips && trips.length && selected >= 0 && trips[selected] && "passes" in trips[selected]) {
      trips[selected].passes.forEach(({ location, distance, gps_available, course, speed, time, snapped_distance }, i) => {
        if ((gps_available == null || gps_available) && (snapped_distance == null || snapped_distance <= SNAPPED_THRESH)) {
          let mColor = ""
          if (distance < 50) {
            mColor = "#F04326";
          } else if (distance < 100) {
            mColor = "#FCA851";
          } else if (distance < 150) {
            mColor = "#E6C655";
          } else if (distance < 200) {
            mColor = "#E7FC51";
          } else {
            mColor = "#41C141";
          }

          let label = {
            text: ' '
          };
          let strokeWeight = 1;
          if (zoom > 16) {
            strokeWeight = 2;
            label = {
              text: Math.round(distance).toString(),
              color: "#333",
              fontWeight: "bold"
            };
          }
          if (location) {
            const marker = new google.maps.Marker({
              map,
              key: i,
              position: { lat: location['_lat'], lng: location['_long'] },
              label,
              title: time.toDate().toLocaleTimeString(),//Math.round(distance).toString(),
              icon: {
                // path: google.maps.SymbolPath.CIRCLE,
                path: 'm5.636 6.63597 6.364-6.363997 6.364 6.363997c1.2587 1.25868 2.1158 2.86233 2.4631 4.60813.3472 1.7458.169 3.5554-.5122 5.2-.6812 1.6445-1.8347 3.0501-3.3148 4.039-1.48.989-3.2201 1.5168-5.0001 1.5168s-3.52008-.5278-5.00012-1.5168c-1.48004-.9889-2.63359-2.3945-3.31478-4.039-.68119-1.6446-.85943-3.4542-.51217-5.2s1.20441-3.34945 2.46307-4.60813z',
                fillColor: mColor,
                fillOpacity: 0.9,
                anchor: new google.maps.Point(
                  12, // width
                  12 // height
                ),
                strokeWeight,
                strokeColor: "#333",
                scale: Math.min(2, Math.max(.7,-0.0571429*zoom*zoom + 4*zoom-49.1429)),
                labelOrigin: new google.maps.Point(12,12),
                rotation: course
              },
            });
            const infowindow = new google.maps.InfoWindow({
              content: ReactDOMServer.renderToString(<InfoWindowContentWrapped time={time.toDate().toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })} distance={Math.round(distance)} speed={speed} course={course}/>)
            });
  
            // line.addListener("mouseover", (event) => {
            marker.addListener("click", (event) => {
              infowindow.setPosition(event.latLng);
              infowindow.open({map});
            });
  
            marker.addListener('mouseout', () => {
              infowindow.close();
            });
            markersT.push(marker);
          }
        }
      })
    }

    // show a selected road and its different road types
    if (!allTrips && trips.length) {
      trips.forEach((trip, indexOuter) => {
        if (selected==indexOuter || hovered==indexOuter) {
          const segments = [];
          let currentSeg = [];
          let pastOnRoad = false;
          let onRoad = true;
          let gpsAvailable = true;
          if ("points" in trip) {
            trip.points.forEach(({ location, location_unprocessed, snapped_distance }, index) => {
              if (location_unprocessed == null) {
                if (location != null) {
                  location_unprocessed = location;
                } else {
                  return
                }
              } 
              // we lose gps so write out previous segment
              if ((location_unprocessed['_lat']==0 || location_unprocessed['_lng']==0) && currentSeg.length > 0) {
                if (gpsAvailable) {
                  segments.push([gpsAvailable, pastOnRoad, currentSeg])
                  currentSeg = currentSeg.slice(-1);
                }
                gpsAvailable = false;
              } else {
                // else we have gps, and need to determine if it's on or off road
                if (!location || (snapped_distance && snapped_distance > SNAPPED_THRESH)) {
                  onRoad = false;
                  location = location_unprocessed;
                } else {
                  onRoad = true;
                }
                // handle non-gps seg
                if (!gpsAvailable) {
                  currentSeg.push({ lat: location['_lat'], lng: location['_long'] })
                  segments.push([gpsAvailable, pastOnRoad, currentSeg])
                  currentSeg = [];
                }
                gpsAvailable = true;

                // if first point set pastOnRoad
                if (index == 0) {
                  pastOnRoad = onRoad;
                }
                if (onRoad != pastOnRoad) {
                  // add and reset in different orders so only lines that have both endpoints on road are in 'on road' segment
                  if (!onRoad) {
                    segments.push([gpsAvailable, pastOnRoad, currentSeg])
                    
                    currentSeg = currentSeg.slice(-1); // take the last on road point

                    currentSeg.push({ lat: location['_lat'], lng: location['_long'] });
                  } else {
                    currentSeg.push({ lat: location['_lat'], lng: location['_long'] });

                    segments.push([gpsAvailable, pastOnRoad, currentSeg])
                    currentSeg = [];
                  }
                }
                pastOnRoad = onRoad;
                currentSeg.push({ lat: location['_lat'], lng: location['_long'] });
                
                // if last point
                if (index == trip.points.length-1) {
                  segments.push([gpsAvailable, onRoad, currentSeg])
                }
              }
            });
          }

          var lineSymbol = {
            path: 'M 0,0 0,.2',
            strokeOpacity: 1,
            scale: 4,
            strokeOpacity: 0.8,
            strokeWeight: 4,
            strokeColor: '#bf4e00',
          };

          segments.forEach((segment, indexInner) => {
            if (!segment[0]) {
              const line = new google.maps.Polyline({
                map,
                path: segment[2],
                strokeOpacity: 0.5,
                strokeWeight: 4,
                strokeColor: '#333',
                key: trip.id+indexInner.toString(),
              });
              linesT.push(line);
            } else if (segment[1]) {
              const line = new google.maps.Polyline({
                map,
                path: segment[2],
                strokeOpacity: 0.8,
                strokeWeight: 4,
                strokeColor: '#bf4e00',
                key: trip.id+indexInner.toString(),
              });
              linesT.push(line);
            } else {
              const line = new google.maps.Polyline({
                map,
                path: segment[2],
                strokeOpacity: 0.0,
                // strokeWeight: 4,
                // strokeColor: '#333',
                key: trip.id+indexInner.toString(),
                icons: [{
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '10px'
                }],
              });
              linesT.push(line);
            }
          })
        }
      })
    }

    //
    if (!allTrips && rawPoints) {
      trips.forEach((trip, indexOuter) => {
        if (selected==indexOuter || hovered==indexOuter) {
          const segments = [];
          let currentSeg = [];
          let curGps = false;
          trip.points.forEach(({ location_unprocessed }, index) => {
            if (location_unprocessed) {
              const gps_available = location_unprocessed['_lat']!=0;
              if (index > 2 && gps_available !== curGps) {
                if (!gps_available) {
                  segments.push([curGps, currentSeg])
                  currentSeg = currentSeg.slice(-1);

                  currentSeg.push({ lat: location_unprocessed['_lat'], lng: location_unprocessed['_long'] });
                } else {
                  currentSeg.push({ lat: location_unprocessed['_lat'], lng: location_unprocessed['_long'] });

                  segments.push([curGps, currentSeg])
                  currentSeg = [];
                }
              }
              curGps = gps_available;
              currentSeg.push({ lat: location_unprocessed['_lat'], lng: location_unprocessed['_long'] });

              if (index == trip.points.length-1) {
                segments.push([curGps, currentSeg])
              }
            }
          });

          segments.forEach((segment, indexInner) => {
            const line = new google.maps.Polyline({
              map,
              path: segment[1],
              strokeOpacity: segment[0] ? 0.8 : 0.2,
              strokeWeight: 4,
              strokeColor: segment[0] ? '#099' : '#333',
              key: trip.id+indexInner.toString(),
            });
            linesT.push(line);
          })
        }
      })
    }

    // plot temperature data
    if (!allTrips && zoom > 17 && trips.length && selected >= 0 && trips[selected].temps) {
      trips[selected].temps.forEach(({location, temperature_ambient, temperature_surface, time, snapped_distance}, i) => {
        if (i % 25 == 0 && location && (snapped_distance == null || (snapped_distance != null && snapped_distance <= SNAPPED_THRESH))) {
          let mColor = ""
          if (temperature_surface < 10) {
            mColor = "#5082F0";
          } else if (temperature_surface < 20) {
            mColor = "#9AACFA";
          } else if (temperature_surface < 40) {
            mColor = "#D7C9E4";
          } else if (temperature_surface < 60) {
            mColor = "#FA8F92";
          } else {
            mColor = "#F05738";
          }

          let aColor = ""
          if (temperature_ambient < 10) {
            aColor = "#5082F0";
          } else if (temperature_ambient < 20) {
            aColor = "#9AACFA";
          } else if (temperature_ambient < 40) {
            aColor = "#D7C9E4";
          } else if (temperature_ambient < 60) {
            aColor = "#FA8F92";
          } else {
            aColor = "#F05738";
          }

          const marker = new google.maps.Marker({
            map,
            key: i,
            position: { lat: location['_lat'], lng: location['_long'] },
            label: {
              text: Math.round(temperature_surface).toString(),
              color: "#333",
              fontWeight: "bold",
              fontSize: "12px"
            },
            title: 'Time: ' + time.toDate().toLocaleTimeString() + ' | RoadTemp: ' + temperature_surface.toFixed(1).toString() +'°C | AmbTemp: '+temperature_ambient.toFixed(1).toString() +'°C',
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              fillColor: mColor,
              fillOpacity: 0.9,
              anchor: new google.maps.Point(
                0, // width
                0 // height
              ),
              strokeWeight: 4,
              strokeColor: aColor,
              scale: 12,
              labelOrigin: new google.maps.Point(0,0),
            },
          });
          markersT.push(marker);
        }
      })
    }

    setMarkers(markersT);
    setLines(linesT);
    
    return () => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      setMarkers([]);
      lines.forEach((line) => {
        line.setMap(null);
      });
      setLines([]);
    };
  }, [allTrips, globalPasses, trips, selected, zoom, center, bounds]);

  return <div ref={ref} id="map"  style={{display:"flex", height:"100%"}} />;
}

  

const DashboardMap = ({ rawPoints, allTrips, setAllLoaded }) => {
  return (
    <Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={render} libraries={['geometry']} >
      <MyMapComponent rawPoints={rawPoints} allTrips={allTrips} setAllLoaded={setAllLoaded} />
    </Wrapper>
  )
};

export default DashboardMap;