import * as React from 'react';
import withRoot from '../withRoot';
import {
  Box,
  Grid,
  ImageList,
  ImageListItem
} from '@mui/material';
import KeepMeUpdatedForm from './KeepMeUpdatedForm';

function EligibilityClosed({ submitAction }) {

  // const onSub = () => {
  //   submitAction(false);
  // }

  return (
    <>
      <Box>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          Due to high interest and participation, we are currently not taking any more registrations, however you can check back at a later date, or sign up to be emailed with updates. Thanks for your interest in the project.</Grid>
        <Box sx={{mt: 2}}>
          <KeepMeUpdatedForm submitAction={submitAction} text={false}/>
        </Box>
      </Box>
    </>
  )
}

export default withRoot(EligibilityClosed);
