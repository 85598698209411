import  React from 'react';
import withRoot from '../withRoot';
import { Formik } from 'formik';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import Button from '../components/Button';

const textStyle = {
  p: 2,
  borderBottom: {
    md: "0px solid rgba(0, 0, 0, 0.12)",
    xs: "1px solid rgba(0, 0, 0, 0.12)",
  },
  borderRight: {
    md: "1px solid rgba(0, 0, 0, 0.12)",
    xs: "0px solid rgba(0, 0, 0, 0.12)",
  }
};

function EligibilityQuestions({ submitAction, setKms, setLocation }) {

  const onSub = (b, kms, location)  => {
    setLocation(location);
    setKms(kms);
    submitAction(b);
  }

  return (
    <>
      <Box sx={{ mt: 3}}>
        To register your interest in participating, and to determine whether you are eligible, please complete the eligibility survey form below:
        <Formik
          initialValues={{
            q1: false,
            q2: false,
            q3: 'Other',
            q4: false,
            q5: '0',
            q6: false,
            q7: false,
            q8: false,
            q9: false
          }}
          onSubmit={async (values) => {
            if (values.q1 && values.q2 && values.q3 != 'Other' && values.q4 && Number(values.q5)>0 && values.q5 && values.q6 && values.q7 && values.q8 && values.q9) {
              onSub(true, Number(values.q5), values.q3);
            } else {
              onSub(false);
            }
          }}
        >
          {({ handleChange, handleSubmit, values}) => (
            <form onSubmit={handleSubmit} >
              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Are you aged 18 or older?
                  </Grid>
                  <Grid item  md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q1" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Are you a regular rider (i.e. commute to/from work most days or take a long ride on the weekend)?
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q2" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Where will you be riding?
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Select
                        id="outlined-basic"
                        name="q3"
                        value={values.q3}
                        label="Location"
                        onChange={handleChange}
                        color="secondary"
                      >
                        <MenuItem value={'Adelaide'}>Adelaide</MenuItem>
                        {/* <MenuItem value={'Melbourne'}>Melbourne</MenuItem> */}
                        <MenuItem value={'Canberra'}>Canberra</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Is a significant amount of your riding on the road (including bike lanes)? 
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q4" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Approximately, how many kilometres do you ride per week? 
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{p: 1, minWidth: '60px'}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <TextField
                        id="outlined-basic"
                        label="km"
                        variant="outlined"
                        value={values.q5}
                        name="q5"
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Do you store your bike in a secure place (i.e. within your home or office, rather than chained up in a public place)? 
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q6" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Are you willing to participate in the trial which involves having a small electronic device mounted on your bicycle for a period of up to four weeks?
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q7" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Are you willing to have your regular cycling trips monitored? This includes information regarding your speed and location, which is obtained through the device’s GPS (it will be possible to turn the device off if you do not wish to have a particular journey recorded). 
                  </Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q8" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper sx={{mt: 3, bgcolor: 'secondary.light'}}>
                <Grid container spacing={0}>
                  <Grid item md={10} xs={12} sx={textStyle}>
                    Are you comfortable using technology? For example, navigating to websites, plugging devices into USB, navigating to files on a computer?</Grid>
                  <Grid item md={2} xs={12} justifyContent="center" alignItems="center" sx={{py: 1}}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                      <Typography>No</Typography>
                      <Switch color="secondary" label="" onChange={handleChange} name="q9" />
                      <Typography>Yes</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
              <Button
                type="submit"
                sx={{ mt: 3, mb: 2 }}
                color="secondary"
                size="large"
                variant="contained"
                fullWidth
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default withRoot(EligibilityQuestions);
