import * as React from 'react';
import {
  AppBar,
  Box,
  ImageList,
  ImageListItem,
  Link,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import Login from './Login';
import Maintenance from './Maintenance';

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'flex-start',
          backgroundColor: 'white',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          },
          paddingBottom: {
            sm: 0,
            xs: 2
          }
        }}>
          <Box
            sx={{
              minWidth: {
                sm: '50px',
                xs: '0px'
              }
            }}
          >
            <ImageList sx={{
              width: 90,
              display: {
                sm: 'block',
                xs: 'none'
            },           
            }} cols={1}>
              <ImageListItem>
                <img src={`/Velograph_logo_trans.png`} alt="bike logo" loading="lazy" />
              </ImageListItem>
            </ImageList>
          </Box>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: 24 }}
          >
            <Stack direction="row" spacing={2} justifyContent="left" alignItems="left" sx={{
              mt: {
                sm: 0.5,
                xs: 1.5
              }
          }}>   
              <Typography variant="h2" color="primary.main">PROJECT</Typography>
              <Typography variant="h2" color="secondary.main">VELOGRAPH</Typography>
            </Stack>
          </Link>
          <Login />
          {/* <Maintenance /> */}
        </Toolbar>
      </AppBar>
      <Toolbar  sx={{
        minHeight: {
          sm: '56px',
          xs: '118px'
        }
      }}/>
    </div>
  );
}

export default AppAppBar;
