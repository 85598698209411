import * as React from 'react';
import withRoot from '../withRoot';
import {
  Box,
  Grid,
  ImageList,
  ImageListItem
} from '@mui/material';
import KeepMeUpdatedForm from './KeepMeUpdatedForm';

function EligibilityNope({ submitAction }) {

  // const onSub = () => {
  //   submitAction(false);
  // }

  return (
    <>
      <Box>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          Unfortunately, you are not eligible to participate in the project. Thanks for your interest. If you would like to keep updated on the project progress, you are welcome to sign up to our mailing list for updates. 
        </Grid>
        <Box sx={{mt: 2}}>
          <KeepMeUpdatedForm submitAction={submitAction} text={false}/>
        </Box>
      </Box>
    </>
  )
}

export default withRoot(EligibilityNope);
