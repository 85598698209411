import {
    Redirect,
    Route
} from "react-router-dom";
import { useRecoilState } from "recoil";
import { authState } from '../../store';

function PrivateRoute ({ children, ...rest }) {
  const [authS, setAuthS] = useRecoilState(authState);

    return (
      <Route {...rest} render={() => {
        return authS.authenticated === true
          ? children
          : <Redirect to='/' />
      }} />
    )
  }
  
  export default PrivateRoute;