import * as React from 'react';
import withRoot from '../withRoot';
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Stack,
} from '@mui/material';
import Typography from '../components/Typography';
import Button from '../components/Button';
import { FileDropzone } from '../components/FileDropzone';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useRecoilState } from 'recoil';
import { authState } from '../../store';
import toast from 'react-hot-toast';
import { httpsCallable } from "firebase/functions";
import { functions } from '../../environment';


const Upload = ({ setIsLoading, setRefreshTrips }) => {

  const [files, setFiles] = React.useState([]);
  const [authS, setAuthS] = useRecoilState(authState);

  const [open, setOpen] = React.useState(false);
  const [merging, setMerging] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrop = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles(files.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleUpload = async () => {
    // const addTripToDB = httpsCallable(functions, 'uploads-addTripToDB');
    // const mergeTrips = httpsCallable(functions, 'uploads-mergeTrips');
    const addTripToDB = httpsCallable(functions, 'uploads-addtriptodbv2');
    const mergeTrips = httpsCallable(functions, 'uploads-mergetripsv2');

    setIsLoading(true);
    await Promise.all(files.map(async (file, index) => {
      // Upload the file and await a result
      const result = await UploadFile(file, authS.user, (progress) => updateProgress(index, progress));
      if (result.status == 'Success') {
        toast.success(result.message);
      } else {
        toast.error(file.name + ' - ' + result.message);
      }

      // Process the files into the database and wait for a result
      try {
        const resultB = addTripToDB({filename: file.name, user: authS.user});
        // if (resultB.data.status == 'Warning') {
        //   toast(resultB.data.message, { icon: '⚠️', duration: 20000 });
        // } else if (resultB.data.status == 'Success') {
        //   toast.success(resultB.data.message);
        // } else {
        //   toast.error(file.name + ' - ' + resultB.data.message);
        // }
      } catch (err) {
        if (err.message == 'INTERNAL') {
          toast.error(file.name + ' - Unknown Error. Please try again.', {duration: 20000});
        } else {
          toast.error(file.name + ' - ' + err.message, {duration: 20000});
        }
      }
    }));

    // setMerging(true);
    // setFiles([]);
    
    // // Once all the files have been added to the database, perform a merge check
    // try {
    //   const resultC = await mergeTrips({user: authS.user})
    //   if (resultC.data.status == 'Warning') {
    //     toast(resultC.data.message, { icon: '⚠️', });
    //   } else if (resultC.data.status == 'Success') {
    //     toast.success(resultC.data.message);
    //   } else {
    //     toast.error(resultC.data.message);
    //   }
    // } catch (err) {
    //   toast.error(err.message);
    // }
    
    // setMerging(false);
    handleClose();
    setIsLoading(false);
    setRefreshTrips(true);
  };

  const updateProgress = (i, progress) => {
    let tempFiles = [...files];
    tempFiles[i].progress = progress;
    setFiles(tempFiles);
  };

  const UploadFile = (file, uid, updateProgress) => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
  
      // Create the file metadata
      const metadata = {
        contentType: 'bin'
      };
    
      // Upload file and metadata to the object
      const storageRef = ref(storage, uid + '/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    
      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          updateProgress(progress);
          switch (snapshot.state) {
            case 'paused':
              // console.log('Upload is paused');
              break;
            case 'running':
              // console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;
    
            // ...
    
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          return resolve({status: 'Error', message: 'Trip ' + file.name + ' was not uploaded - ' + error.message});
        }, 
        async () => {
          // Upload completed successfully, now we can get the download URL
          // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //   console.log('File available at', downloadURL);
          // });
          return resolve({status: 'Success', message: 'Trip ' + file.name + ' successfully uploaded'});
        }
      );
    })
    
  }

  return (
    <>
      <Button onClick={handleOpen('body')} color="secondary" size="large" variant="contained" fullWidth>New Trip</Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" fullWidth maxWidth="md">
        <DialogContent dividers={scroll === 'paper'}>
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Upload Trip
            </Typography>
          </Box>
          <Container
            component="section"
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 1, p: 0 }}
          >
            <Box alignItems="center" justifyContent="center" textAlign="center">
              {!merging ?
                <FileDropzone
                  accept=".velo"
                  files={files}
                  maxFiles={50}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  onUpload={handleUpload}
                />
              :
              <Stack sx={{ width: '100%', display: 'flex', justifyContent: "center", p: 10 }}>
                  <Typography variant="h6">Merging Trips</Typography>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: "center", p: 2 }}>
                    <CircularProgress />
                  </Box>
              </Stack>
              }
              {/* <Typography variant="h6">Site Under maintenence, check back later</Typography> */}
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withRoot(Upload);
