
import { atom } from 'recoil';

export const authState = atom({
  key: 'authState',
  default: {
    authenticated: false,
    initialised: false,
    user: null,
    email: null
  }
})

export const userState = atom({
  key: 'userState',
  default: null,
}) 

export const tripsState = atom({
  key: 'tripsState',
  default: []
}) 

export const selectedState = atom({
  key: 'selectedState',
  default: -1,
}) 
export const hoveredState = atom({
  key: 'hoveredState',
  default: -1,
}) 