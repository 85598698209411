import * as React from 'react';
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  Popover,
  Stack,
  Switch,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { db, functions, storage } from '../../environment';
import { useRecoilState } from 'recoil';
import { authState } from '../../store';
import { Redirect } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import Button from '../components/Button';
import DetailsDialog from './DetailsDialog';
import AdminPanel from './AdminPanel';
import { httpsCallable } from "firebase/functions";
import toast from 'react-hot-toast';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { saveAs } from 'file-saver';

function DashAppBar({rawPoints, setRawPoints, publicView, setPublicView, allTrips, setAllTrips, allLoaded}) {
  
  const [authS, setAuthS] = useRecoilState(authState);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userData, setUserData] = React.useState({});
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
  const [adminPanelDialogOpen, setAdminPanelDialogOpen] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [downloadingTemp, setDownloadingTemp] = React.useState(false);

  const handleOpenHelp = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHelp = () => {
    setAnchorEl(null);
  };

  const downloadTemps = async () => {
    setDownloadingTemp(true);
    const downloadData = httpsCallable(functions, 'downloads-downloadTempData', {timeout: 540000});
    try {
      const result = await downloadData({user: authS.user});
      if (result.data.status == 'Warning') {
        toast(result.data.message, { icon: '⚠️', duration: 20000 });
      } else if (result.data.status == 'Success') {
        // toast.success(result.data.message);
        await getDownloadURL(ref(storage, result.data.path))
          .then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              'temps.csv',
            );

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
          })
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      console.log(err);
      if (err.message == 'internal') {
        toast.error('Unknown Error. Please try again.', {duration: 20000});
      } else {
        toast.error(err.message, {duration: 20000});
      }
    } finally {
      setDownloadingTemp(false);
    }
  };

  const downloadPersonal = async () => {
    setDownloading(true);
    const downloadData = httpsCallable(functions, 'downloads-downloadUserData', {timeout: 540000});
    try {
      const result = await downloadData({user: authS.user});
      if (result.data.status == 'Warning') {
        toast(result.data.message, { icon: '⚠️', duration: 20000 });
      } else if (result.data.status == 'Success') {
        // toast.success(result.data.message);
        await getDownloadURL(ref(storage, result.data.path))
          .then(async (url) => {
            // saveAs(url, 'data.json');
            let response = await fetch(url);
            let responseJson = await response.json();
            console.log(responseJson)
            const link = document.createElement('a');
            // link.href = url;
            link.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(responseJson));
            link.download = 'data.json';
            // link.setAttribute(
            //   'download',
            //   'data.json',
            // );

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
          })
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      console.log(err);
      if (err.message == 'internal') {
        toast.error('Unknown Error. Please try again.', {duration: 20000});
      } else {
        toast.error(err.message, {duration: 20000});
      }
    } finally {
      setDownloading(false);
    }
  };

  const openHelp = Boolean(anchorEl);
  const id = openHelp ? 'simple-popover' : undefined;

  const logout = () => {
    setAuthS({
      authenticated: false,
      initialised: false,
      user: null,
      email: null
    })
    return <Redirect to="/" />
  }

  React.useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", authS.user), async (docSnap) => {
      const userDataTemp = docSnap.data();
      setUserData(userDataTemp);
    });

    return unsub;
  }, []);

  React.useEffect(async () => {
    try {
      const checkAdmin = await getDoc(doc(db, "admins", authS.user));
      if (checkAdmin.data()) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } catch(e) {
      setIsAdmin(false);
    }
  }, []);

  return (
    <div>
      <AppBar position="relative"
        sx={{
          width: '100vw',
          "z-index": 10
        }}
      >
        <Toolbar sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'flex-start',
          backgroundColor: 'secondary.main',
          flexDirection: {
            sm: 'row',
            xs: 'column'
          },
          paddingBottom: {
            sm: 0,
            xs: 2
          }
        }}>
          <Box
            sx={{
              minWidth: {
                md: '200px',
                sm: '50px',
                xs: '0px'
              }
            }}
          >
            <ImageList sx={{
              width: 90,
              display: {
                sm: 'block',
                xs: 'none'
            },           
            }} cols={1}>
              <ImageListItem>
                <img src={`/Velograph_logo_trans_white.png`} alt="bike logo" loading="lazy" />
              </ImageListItem>
            </ImageList>
          </Box>
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            href="/"
            sx={{ fontSize: 24 }}
          >
            <Stack direction="row" spacing={2} justifyContent="left" alignItems="left" sx={{
              pt: {
                sm: 0.5,
                xs: 1.5
            }
          }}>   
              <Typography variant="h2" color="white">PROJECT</Typography>
              <Typography variant="h2" color="white">VELOGRAPH</Typography>
            </Stack>
          </Link>
          <Stack direction="row" spacing={2}>
            {/* <Box onClick={() => setPublicView(!publicView)} sx={{p: 1, borderRadius: '6px', border: "1px solid rgba(255, 255, 255, 0.5)", cursor: "pointer"}}>
              <Stack direction="row" spacing={0}>
                <Switch label="public" onChange={() => setPublicView(!publicView)} checked={publicView} color="primary" inputProps={{ 'aria-label': 'controlled' }}/>
                <Typography variant="h6" display="block" sx={{pt: 0.95, pr: 1, color: "#FFF"}}>Show Public Map</Typography>
              </Stack>
            </Box> */}
            <Tooltip title="Help">
              <IconButton aria-describedby={id} color="neutral" onClick={handleOpenHelp}>
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={openHelp}
              anchorEl={anchorEl}
              onClose={handleCloseHelp}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container spacing={0.5} sx={{maxWidth: 300, py: 1}}>
                <Grid item xs={12}>
                  <Typography variant="h6" display="block" sx={{mx: 2}}>
                    Legend
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <path fill="#F04326" d='m5.636 6.63597 6.364-6.363997 6.364 6.363997c1.2587 1.25868 2.1158 2.86233 2.4631 4.60813.3472 1.7458.169 3.5554-.5122 5.2-.6812 1.6445-1.8347 3.0501-3.3148 4.039-1.48.989-3.2201 1.5168-5.0001 1.5168s-3.52008-.5278-5.00012-1.5168c-1.48004-.9889-2.63359-2.3945-3.31478-4.039-.68119-1.6446-.85943-3.4542-.51217-5.2s1.20441-3.34945 2.46307-4.60813z' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      Under 0.5m
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <path fill="#FCA851" d='m5.636 6.63597 6.364-6.363997 6.364 6.363997c1.2587 1.25868 2.1158 2.86233 2.4631 4.60813.3472 1.7458.169 3.5554-.5122 5.2-.6812 1.6445-1.8347 3.0501-3.3148 4.039-1.48.989-3.2201 1.5168-5.0001 1.5168s-3.52008-.5278-5.00012-1.5168c-1.48004-.9889-2.63359-2.3945-3.31478-4.039-.68119-1.6446-.85943-3.4542-.51217-5.2s1.20441-3.34945 2.46307-4.60813z' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      0.5m - 1m
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <path fill="#E6C655" d='m5.636 6.63597 6.364-6.363997 6.364 6.363997c1.2587 1.25868 2.1158 2.86233 2.4631 4.60813.3472 1.7458.169 3.5554-.5122 5.2-.6812 1.6445-1.8347 3.0501-3.3148 4.039-1.48.989-3.2201 1.5168-5.0001 1.5168s-3.52008-.5278-5.00012-1.5168c-1.48004-.9889-2.63359-2.3945-3.31478-4.039-.68119-1.6446-.85943-3.4542-.51217-5.2s1.20441-3.34945 2.46307-4.60813z' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      1m - 1.5m
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <path fill="#E7FC51" d='m5.636 6.63597 6.364-6.363997 6.364 6.363997c1.2587 1.25868 2.1158 2.86233 2.4631 4.60813.3472 1.7458.169 3.5554-.5122 5.2-.6812 1.6445-1.8347 3.0501-3.3148 4.039-1.48.989-3.2201 1.5168-5.0001 1.5168s-3.52008-.5278-5.00012-1.5168c-1.48004-.9889-2.63359-2.3945-3.31478-4.039-.68119-1.6446-.85943-3.4542-.51217-5.2s1.20441-3.34945 2.46307-4.60813z' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      1.5M - 2M
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <path fill="#41C141" d='m5.636 6.63597 6.364-6.363997 6.364 6.363997c1.2587 1.25868 2.1158 2.86233 2.4631 4.60813.3472 1.7458.169 3.5554-.5122 5.2-.6812 1.6445-1.8347 3.0501-3.3148 4.039-1.48.989-3.2201 1.5168-5.0001 1.5168s-3.52008-.5278-5.00012-1.5168c-1.48004-.9889-2.63359-2.3945-3.31478-4.039-.68119-1.6446-.85943-3.4542-.51217-5.2s1.20441-3.34945 2.46307-4.60813z' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      Over 2m
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                <Stack direction="row" spacing={.5} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <circle cx="11" cy="11" r="7" stroke="#FA8F92" strokeWidth="3" fill="#D7C9E4" />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      Temperature
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction="row" spacing={.5} sx={{mx: 2}}>
                    <svg viewBox="0 0 30 25" width={20}>
                      <path fill="#F04326" strokeWidth="6" stroke="#F04326" strokeLinecap="round" d='M2 10 l20 0' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      Road
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction="row" spacing={.5} sx={{mx: 2}}>
                    <svg viewBox="0 0 25 25" width={20}>
                      <path fill="#F04326" strokeWidth="7" stroke="#F04326" strokeLinecap="round" strokeDasharray="3,10" d="M5 10 l215 0"  />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      OffRoad
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction="row" spacing={.5} sx={{mx: 2}}>
                    <svg viewBox="0 0 30 25" width={20}>
                      <path fill="#333" strokeWidth="6" stroke="#333" strokeOpacity="0.5" strokeLinecap="round" d='M2 10 l20 0' />
                    </svg>
                    <Typography variant="subtitle1" display="block">
                      NO GPS
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  pt: 1
                }}
              >
                <Typography variant="h6" display="block" sx={{ml: 2}}>
                  Rider Details
                </Typography>
                <Box>
                  <Stack direction={'row'}>
                    <Tooltip title="Edit">
                      <IconButton size="small" onClick={() => setDetailsDialogOpen(true)} color="secondary" sx={{mt: 0, pt: 0, pb: 1}}>
                        <EditIcon fontSize="tiny" />
                      <Typography variant="subtitle1" display="block" color="secondary" sx={{mr: 1, mt:.5}}>
                        Edit
                      </Typography>
                      </IconButton>
                    </Tooltip>
                    
                  </Stack>
                  <DetailsDialog detailsDialogOpen={detailsDialogOpen} setDetailsDialogOpen={setDetailsDialogOpen} first={false}/>
                </Box>
              </Box>
              <Grid container spacing={0.5} sx={{maxWidth: 300}}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                    ID: <b>{authS.email.split("@")[0]}</b>
                  </Typography>
                </Grid>
                {userData.age != null && 
                  <Grid item xs={3}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Age: <b>{userData.age}</b>
                    </Typography>
                  </Grid>
                }
                {userData.gender != null && 
                  <Grid item xs={5}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Gender: <b>{userData.gender}</b>
                    </Typography>
                  </Grid>
                }
                {userData.typebike != null && 
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Bicycle Type: <b>{userData.typebike}</b>
                    </Typography>
                  </Grid>
                }
                {userData.typepedals != null && 
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Pedals Type: <b>{userData.typepedals}</b>
                    </Typography>
                  </Grid>
                }
                {userData.senslong != null &&
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Longitudinal difference: <b>{userData.senslong}cm</b>
                    </Typography>
                  </Grid>
                }
                {userData.sensheight != null &&
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Height difference: <b>{userData.sensheight}cm</b>
                    </Typography>
                  </Grid>
                }
                {userData.senslat != null &&
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Rear lateral offset: <b>{userData.senslat}cm</b>
                    </Typography>
                  </Grid>
                }
                {userData.handlebar != null &&
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" display="block" sx={{mx: 2}}>
                      Handlebar offset: <b>{userData.handlebar}cm</b>
                    </Typography>
                  </Grid>
                }
              </Grid>
              {isAdmin &&
              <>
                <Divider />
                <Stack direction="row" spacing={0}>
                  {!downloading ?
                  <Button fullWidth variant="contained" color="secondary" onClick={() => downloadPersonal()} sx={{py:.5, px: 1, m: 1}}>
                    Download My Data
                  </Button>
                  :
                  <>
                    <CircularProgress size="1rem" sx={{ml: 1.5, mt: 1, mb: 1}}/>
                    <Typography variant="subtitle1" display="block" sx={{ml: 1.2, pt: 1.2}}>Generating...</Typography>
                  </>
                  }
                </Stack>
                <Divider />
              </>
              }
              {isAdmin &&
                <>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      pt: 1
                    }}
                  >
                    <Typography variant="h6" display="block" sx={{mx: 2}}>
                      Admin Controls
                    </Typography>
                    <Box sx={{mr: 1}}>
                      <Button variant="contained" color="secondary" onClick={() => setAdminPanelDialogOpen(true)} size='small' sx={{py:.5, px: 1}}>
                        Open Admin Panel
                      </Button>
                      <AdminPanel adminPanelDialogOpen={adminPanelDialogOpen} setAdminPanelDialogOpen={setAdminPanelDialogOpen}/>
                    </Box>
                  </Box>
                  <Grid container spacing={0.5} sx={{maxWidth: 300}}>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={0}>
                        <Switch label="rawGps" onChange={() => setRawPoints(!rawPoints)} checked={rawPoints} color="secondary" inputProps={{ 'aria-label': 'controlled' }}/>
                        <Typography variant="subtitle1" display="block" sx={{pt: 1.2}}>Show raw GPS</Typography>
                      </Stack>
                      <Stack direction="row" spacing={0}>
                        <Switch label="public" onChange={() => setPublicView(!publicView)} checked={publicView} color="secondary" inputProps={{ 'aria-label': 'controlled' }}/>
                        <Typography variant="subtitle1" display="block" sx={{pt: 1.2}}>Show Public Map</Typography>
                      </Stack>
                      <Stack direction="row" spacing={0}>
                        <Switch label="allTrips" onChange={() => setAllTrips(!allTrips)} checked={allTrips} color="secondary" inputProps={{ 'aria-label': 'controlled' }}/>
                        <Typography variant="subtitle1" display="block" sx={{pt: 1.2}}>Show All Passes</Typography>
                        {!allLoaded && <CircularProgress size="1rem" sx={{ml: 1.2, mt: 1}}/>}
                      </Stack>
                      {/* <Stack direction="row" spacing={0}>
                        {!downloadingTemp ?
                        <Button variant="contained" color="secondary" onClick={() => downloadTemps()} size='small' sx={{py:.5, px: 1, m: 1}}>
                          Download Temperature CSV
                        </Button>
                        :
                        <>
                          <CircularProgress size="1rem" sx={{ml: 1.5, mt: 1, mb: 1}}/>
                          <Typography variant="subtitle1" display="block" sx={{ml: 1.2, pt: 1.2}}>Generating...</Typography>
                        </>
                        }
                      </Stack> */}
                    </Grid>
                  </Grid>
                </>
              }
            </Popover>
            <Button onClick={() => logout()} color="neutral" size="large" variant="outlined" sx={{color: 'white'}}>
              Logout
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default DashAppBar;
