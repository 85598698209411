import * as React from 'react';
import {
  Box,
  Container,
  Grid
} from '@mui/material';
import Typography from '../components/Typography';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreateIcon from '@mui/icons-material/Create';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SchoolIcon from '@mui/icons-material/School';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: {
    sm: 5,
    xs: 10
  }
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
};

const icon = {
  width: 60,
  height: 60,
  margin: 5,
};

function HowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Typography variant="h2" marked="center" component="h2" sx={{ mb: 5 }}>
          How it works
        </Typography>
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <CreateIcon sx={icon} />
                <Typography variant="h5" align="center">
                Register your interest by filling out the eligibility survey
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                  <ThumbUpIcon sx={icon} />
                <Typography variant="h5" align="center">
                  Join pool of participants and nominate data collection period
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <SchoolIcon sx={icon} />
                <Typography variant="h5" align="center">
                  Attend nominated session with your bicycle for briefing and device attachment
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Box sx={item}>
                <Box sx={number}>4.</Box>
                <DirectionsBikeIcon sx={icon} />
                <Typography variant="h5" align="center">
                  Ride your normal routes and upload recorded journey data at project portal
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={item}>
                <Box sx={number}>5.</Box>
                  <DoneIcon sx={icon} />
                <Typography variant="h5" align="center">
                  Attend nominated session to return device
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={item}>
                <Box sx={number}>6.</Box>
                <SearchIcon sx={icon} />
                <Typography variant="h5" align="center">
                  Check project website to see data develop and project outputs
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      </Container>
    </Box>
  );
}

export default HowItWorks;
