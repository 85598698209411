import * as React from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  ImageList,
  ImageListItem
} from '@mui/material';
import Typography from '../components/Typography';
import BikePic from '../../static/bike_example_1_edit.png';
import RSPpic from '../../static/rsp2.svg';
import ACTpic from '../../static/act.png';


function About() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
    <Typography variant="h2" marked="center" component="h2" sx={{ mb: 5 }}>
      About
    </Typography>
    <Box alignItems="center" justifyContent="center" textAlign="center">
      Project Velograph aims to collect data from cyclists as they travel around the public road network. 
      This is achieved via data collection devices that attach to cyclists' personal bicycles and record information as they ride around. 
      The current project aims to collect data about the routes cyclists take and the distance vehicles provide to cyclists as they pass (overtake). 
      This information will be used to help improve safety for cyclists. 
      The project is run through the University of Adelaide's <Link href="https://ecms.adelaide.edu.au/centre-for-automotive-safety-research/" rel="noopener">Centre for Automotive Safety Research (CASR)</Link>, with partners <Link href="https://ivai.com.au/" rel="noopener">Insight via Artificial Intelligence (IVAI)</Link>, the <Link href="https://www.bicyclenetwork.com.au/" rel="noopener">Bicycle Network</Link> and <Link href="https://www.pedalpower.org.au/" rel="noopener">Pedal Power ACT</Link>. 
      The project has received funding from the Australian Government through a grant from the Office of Road Safety's <Link href="https://www.officeofroadsafety.gov.au/programs/road-safety-innovation-fund" rel="noopener">Road Safety Innovation Fund</Link> as well as the ACT government through a grant from the <Link href="https://www.cityservices.act.gov.au/roads-and-paths/road-safety/road-safety-grants-fund" rel="noopener">ACT Road Safety Fund</Link>.

      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={4}>
          <ImageList
            cols={1}
          > 
            <Link href="https://www.officeofroadsafety.gov.au/programs/road-safety-innovation-fund">
              <ImageListItem sx={{px: 2}}>
                <img
                  src={RSPpic}
                  alt="Road Safety Program"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={4}>
          <ImageList
            cols={1}
          > 
            <Link href="https://www.cityservices.act.gov.au/roads-and-paths/road-safety/road-safety-grants-fund">
              <ImageListItem sx={{px: 2}}>
                <img
                  src={ACTpic}
                  alt="ACT Government"
                />
              </ImageListItem>
            </Link>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={1}></Grid>
      </Grid>      
    </Container>
  );
}

export default About;
