import * as React from 'react';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import splash from '../../static/splash.jpg';
import Register from './Register';
import KeepMeUpdated from './KeepMeUpdated';
import { Stack } from '@mui/material';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${splash})`,
        backgroundColor: '#eb9a44', // Average color of the background image.
        backgroundPosition: 'bottom',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={splash}
        alt="increase priority"
      />
      <Typography
        color="inherit"
        align="center"
        variant="h4"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
        An open research project seeking insights<br />into the safety of our roads for cyclists
      </Typography>
      <Stack direction="row" spacing={2} sx={{mt:3}}>
        {/* <Register /> */}
        <KeepMeUpdated />
      </Stack>
    </ProductHeroLayout>
  );
}
