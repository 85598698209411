import * as React from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  ImageList,
  ImageListItem
} from '@mui/material';
import Typography from '../components/Typography';
import BikePic from '../../static/bike_example_1_edit.png';
import SensorPic from '../../static/bike_example_2_edit.jpg';
import RSPpic from '../../static/rsp2.svg';
import ACTpic from '../../static/act.png';


function TheDevice() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      <Typography variant="h2" marked="center" component="h2" sx={{ mb: 5 }}>
      The Device
      </Typography>
      <Box alignItems="center" justifyContent="center" textAlign="center">

      The Velograph device has been designed to enable the naturalistic collection of passing distance data. The device is compatible with most bicycles and, once switched on, operates automatically without any need for interaction with the cyclist. The Velograph device consists of two parts. The dock unit attaches to the top tube of a bicycle and integrates the two distance sensors (one within the dock unit and one on a cable for mounting at the rear of the bicycle) and a cradle for the main unit. The main unit contains all the electronics that facilitate the data collection, including a microcontroller, internal memory card, battery, and GPS module. The front of the main unit has a USB port where recorded data files can be accessed and to recharge the battery along with a socket array for connecting to the dock unit. An on/off button is mounted on the rear main unit.<br /><br />
      When installed onto a bicycle, the Velograph device is switched on by holding down the on/off button for 2 seconds. The device will then use the GPS module to identify its location. Once a GPS lock has been achieved the device will automatically start logging the location and lateral distance of any objects that pass on the right. All logged data is stored internally, and nothing is displayed on the device itself. The device will continue logging until switched off, or it detects that it has not moved for 15 minutes. If a GPS signal is lost the device will stop logging, wait for the signal to return and restart logging.<br /><br />
      After removing the main unit from the dock, it can be connected to a computer via a USB cable to access the recorded journey files. These files can then be uploaded to the Project Velograph Portal for decoding and visualisation of passing events.<br /><br />
      The device identifies passing events by intelligently comparing the data recorded by the two distance sensors. An object detected initially by the rear sensor and then by the front sensor is noted as a legitimate passing event. If an object is detected first at the front sensor and then at the rear, it is assumed the cyclist is passing slow or stationary traffic and no event is noted. Passing events are only noted while the cyclist is in motion.<br /><br />

      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ImageList
            cols={1}
          > 
            <ImageListItem cols={1} sx={{ mt: 5, mb: 1 }}>
              <img
                src={SensorPic}
                alt="Example of the sensor device"
              />
            </ImageListItem>
          </ImageList>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ImageList
            cols={1}
          > 
            <ImageListItem cols={1} sx={{ mt: 5, mb: 1 }}>
              <img
                src={BikePic}
                alt="Example of the device on the bike"
              />
            </ImageListItem>
          </ImageList>
        </Grid>
      </Grid>
      
    </Container>
  );
}

export default TheDevice;
