import * as React from "react";
import { Box, Chip, Stack } from "@mui/material";
import Typography from "../components/Typography";
import publicMapImage from "../../static/public_map.png";
import PublicMap from "./PublicMap";

function PublicMapWrap() {
  return (
    <Box
      sx={{
        width: "100%",
        height: 800,
        flexGrow: 1,
        mb: -1,
        position: "relative",
        textAlign: "center",
        color: "white",
      }}
    >
      <PublicMap />
      <Box
        sx={{
          // width: "100%",
          position: "absolute",
          top: -6,
          left: 3,
          m: 1,
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            // justifyContent: "center",
            // textAlign: "center",
            pt: 1,
          }}
        >
          <Stack
            direction="row"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1.0)",
              borderRadius: 1,
              px: 2,
              py: 0.5,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Box sx={{ pt: 0.5, mr: 2 }}>
              <Typography
                variant="subtitle1"
                display="block"
                fontWeight="bold"
                sx={{ mx: 1, color: "#333", pt: 0.5 }}
              >
                Mean Distance:
              </Typography>
            </Box>
            <Box sx={{ width: 300 }}>
              <Box
                sx={{
                  height: 5,
                  m: 0.5,
                  borderRadius: 2,
                  background:
                    "linear-gradient(to right, #F04326, #E6C655, #41C141)",
                }}
              />
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  color: "#333",
                  flexGrow: 1,
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="subtitle1"
                  display="block"
                  sx={{ color: "#333" }}
                >
                  1m
                </Typography>
                <Typography
                  variant="subtitle1"
                  display="block"
                  sx={{ color: "#333" }}
                >
                  1.5m
                </Typography>
                <Typography
                  variant="subtitle1"
                  display="block"
                  sx={{ color: "#333" }}
                >
                  2m
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default PublicMapWrap;
