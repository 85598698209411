import * as React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import Button from "../components/Button";
import Typography from "../components/Typography";
import { db, functions, storage } from "../../environment";
import { useRecoilState } from "recoil";
import { authState } from "../../store";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import toast from "react-hot-toast";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const AdminPanel = ({ adminPanelDialogOpen, setAdminPanelDialogOpen }) => {
  const [authS, setAuthS] = useRecoilState(authState);
  const [registrations, setRegistrations] = React.useState([]);
  const [kmus, setKmus] = React.useState([]);
  const [stats, setStats] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [downloadingTemp, setDownloadingTemp] = React.useState(false);
  const [downloadingPasses, setDownloadingPasses] = React.useState(false);
  const [downloadingAllData, setDownloadingAllData] = React.useState(-1);

  const userNotes = {
    eDSnoCUdnyV9cw2nw4UOUb1wDq42: "Adelaide Demonstration Account",
    "4OliOYLMhRMx9VTYilFHoJO191W2": "Canberra Demonstration Account",
    cCI03euklxX1Tvh0QQ6wxhYY3yU2: "Melbourne Demonstration Account",
    lTk8Yh3wdYgusSFzfOEYOVh13qt1: "Giulio",
    "2YpP3JuwOdPK18E0l229R9hiaGn2": "Jamie",
    Qolf20u8IdNLiM942frnKqUjL7s1: "Test Account",
    SXSNbcAA1xR9ljI12BMZneIRB2m1: "Test Account",
  };

  const flip_date = new Date(2023, 5, 1, 0, 0, 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(async () => {
    const registrationsSnap = await getDocs(collection(db, "registrations"));
    setRegistrations(
      registrationsSnap.docs
        .map((doc) => doc.data())
        .sort((a, b) => b.registered - a.registered)
    );

    const kmusSnap = await getDocs(collection(db, "kmu"));
    const kmuss = kmusSnap.docs.map((doc) => doc.data());
    kmuss.forEach((kmu) => {
      if ("registered" in kmu) {
        kmu.registered = kmu.registered.toDate();
      } else {
        kmu.registered = new Date(2023, 0, 1, 0, 0, 0);
      }
    });
    setKmus(kmuss.sort((a, b) => b.registered - a.registered));

    const statsSnap = await getDoc(doc(db, "stats", "general"));
    setStats(statsSnap.data());

    const usersSnap = await getDocs(collection(db, "users"));
    setUsers(
      usersSnap.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => b.trips.length - a.trips.length)
    );
  }, []);

  const downloadTemps = async () => {
    setDownloadingTemp(true);
    const downloadData = httpsCallable(
      functions,
      "downloads-downloadTempData",
      { timeout: 540000 }
    );
    try {
      const result = await downloadData({ user: authS.user });
      if (result.data.status == "Warning") {
        toast(result.data.message, { icon: "⚠️", duration: 20000 });
      } else if (result.data.status == "Success") {
        // toast.success(result.data.message);
        await getDownloadURL(ref(storage, result.data.path)).then((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "temps.csv");

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        });
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      console.log(err);
      if (err.message == "internal") {
        toast.error("Unknown Error. Please try again.", { duration: 20000 });
      } else {
        toast.error(err.message, { duration: 20000 });
      }
    } finally {
      setDownloadingTemp(false);
    }
  };

  const downloadPasses = async () => {
    setDownloadingPasses(true);
    const downloadData = httpsCallable(
      functions,
      "downloads-downloadPassData",
      { timeout: 540000 }
    );
    try {
      const result = await downloadData({ user: authS.user });
      if (result.data.status == "Warning") {
        toast(result.data.message, { icon: "⚠️", duration: 20000 });
      } else if (result.data.status == "Success") {
        // toast.success(result.data.message);
        await getDownloadURL(ref(storage, result.data.path)).then((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "passes.csv");

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        });
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      console.log(err);
      if (err.message == "internal") {
        toast.error("Unknown Error. Please try again.", { duration: 20000 });
      } else {
        toast.error(err.message, { duration: 20000 });
      }
    } finally {
      setDownloadingPasses(false);
    }
  };

  const downloadAllData = async () => {
    setDownloadingAllData(0);
    const downloadData = httpsCallable(
      functions,
      "downloads-downloadalluserdata",
      { timeout: 1200000 }
    );
    try {
      // const result = await downloadData(); // need to use this to regenerate the file, otherwise it will get cached
      const result = {'data': {status: 'Success', message: 'Success', path: 'downloads/all/alldata.json'}};
      if (result.data.status == "Warning") {
        toast(result.data.message, { icon: "⚠️", duration: 20000 });
      } else if (result.data.status == "Success") {
        const url = await getDownloadURL(ref(storage, result.data.path), {timeout: 1200000});
        const handle = await window.showSaveFilePicker({ suggestedName: "allData.json" });
        const writableStream = await handle.createWritable();

        const response = await fetch(url);
        const reader = response.body.getReader();

        const totalSize = parseInt(response.headers.get('Content-Length') || '0');
        let downloadedSize = 0;

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          downloadedSize += value.byteLength;

          // Update progress (you can use this information to update a progress bar)
          const progress = (downloadedSize / totalSize) * 100;
          setDownloadingAllData(progress);

          await writableStream.write(value);
        }

        await writableStream.close();

        console.log('File downloaded successfully');
        toast.success('File downloaded successfully');

  
        
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      console.log(err);
      if (err.message == "internal") {
        toast.error("Unknown Error. Please try again.", { duration: 20000 });
      } else {
        toast.error(err.message, { duration: 20000 });
      }
    } finally {
      setDownloadingAllData(-1);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={adminPanelDialogOpen}
        onClose={() => setAdminPanelDialogOpen(false)}
      >
        <DialogContent>
          <Grid container spacing={1} sx={{ p: 2 }}>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block">
                  {stats.active_users - 17}
                </Typography>
                <Typography variant="h6" display="block">
                  Participants
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block">
                  {stats.total_trips - 315}
                </Typography>
                <Typography variant="h6" display="block">
                  Trips
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block">
                  {Math.round(stats.total_distance) - 1531} km
                </Typography>
                <Typography variant="h6" display="block">
                  Travelled
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h2" display="block">
                  {stats.total_passes - 7362}
                </Typography>
                <Typography variant="h6" display="block">
                  Passes
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Tabs value={value} onChange={handleChange}>
            <Tab label="registrations" />
            <Tab label="keep me updated" />
            <Tab label="user statistics" />
            <Tab label="downloads" />
          </Tabs>
          <TabPanel value={value} index={0}>
            {registrations && registrations.length > 0 && (
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>KMs</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date Registered</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {registrations.map((registration, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {registration.firstName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {registration.lastName}
                          </TableCell>
                          <TableCell>{registration.location}</TableCell>
                          <TableCell>{registration.kms}</TableCell>
                          <TableCell>{registration.phone}</TableCell>
                          <TableCell>{registration.email}</TableCell>
                          <TableCell>
                            {registration.registered.toDate().toDateString()}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {kmus && kmus.length > 0 && (
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Date Registered</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kmus.map((kmu, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {kmu.firstName}
                          </TableCell>
                          <TableCell>{kmu.email}</TableCell>
                          {kmu.registered > flip_date ? (
                            <TableCell>
                              {kmu.registered.toDateString()}
                            </TableCell>
                          ) : (
                            <TableCell>Prior to June 2023</TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {users && users.length > 0 && (
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Age</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Bike Type</TableCell>
                      <TableCell>Pedal Type</TableCell>
                      <TableCell># of Trips</TableCell>
                      <TableCell>Note</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user, i) => {
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <b>{user.id}</b>
                          </TableCell>
                          <TableCell>{user.age}</TableCell>
                          <TableCell>{user.gender}</TableCell>
                          <TableCell>{user.typebike}</TableCell>
                          <TableCell>{user.typepedals}</TableCell>
                          <TableCell>{user.trips.length}</TableCell>
                          <TableCell>{userNotes[user.id] || ""}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid container spacing={0} sx={{ p: 2 }}>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  backgroundColor: "#DDD",
                  borderRadius: 2,
                  p: 2,
                  my: 1,
                }}
              >
                <Typography variant="h6" display="block">
                  Download Temperature Data
                </Typography>
                <Typography variant="body1" display="block">
                  Download all temperature data from the database. Estimated
                  time of 5 minutes.
                </Typography>

                {!downloadingTemp ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => downloadTemps()}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Download Temperature CSV
                  </Button>
                ) : (
                  <Stack direction="row" sx={{ justifyContent: "center" }}>
                    <CircularProgress
                      size="1rem"
                      sx={{ ml: 1.5, mt: 1, mb: 1 }}
                    />
                    <Typography
                      variant="subtitle1"
                      display="block"
                      sx={{ ml: 1, mt: 1 }}
                    >
                      Generating...
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  backgroundColor: "#DDD",
                  borderRadius: 2,
                  p: 2,
                  my: 1,
                }}
              >
                <Typography variant="h6" display="block">
                  Download Pass Data
                </Typography>
                <Typography variant="body1" display="block">
                  Download all pass data from the database. Estimated time of 30
                  seconds.
                </Typography>

                {!downloadingPasses ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => downloadPasses()}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Download Passes CSV
                  </Button>
                ) : (
                  // float stack in middle
                  <Stack direction="row" sx={{ justifyContent: "center" }}>
                    <CircularProgress
                      size="1rem"
                      sx={{ ml: 1.5, mt: 1, mb: 1 }}
                    />
                    <Typography
                      variant="subtitle1"
                      display="block"
                      sx={{ ml: 1, mt: 1 }}
                    >
                      Generating...
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  backgroundColor: "#DDD",
                  borderRadius: 2,
                  p: 2,
                  my: 1,
                }}
              >
                <Typography variant="h6" display="block">
                  Download All Data
                </Typography>
                <Typography variant="body1" display="block">
                  Download all data from the database. Estimated
                  time of 1 minute.
                </Typography>

                {downloadingAllData < 0 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => downloadAllData()}
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Download All Data
                  </Button>
                ) : (
                  <Stack direction="row" sx={{ justifyContent: "center" }}>
                    <CircularProgress
                    variant="determinate" value={downloadingAllData}
                      size="1rem"
                      sx={{ ml: 1.5, mt: 1, mb: 1 }}
                    />
                    <Typography
                      variant="subtitle1"
                      display="block"
                      sx={{ ml: 1, mt: 1 }}
                    >
                      Downloading...
                    </Typography>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={() => setAdminPanelDialogOpen(false)}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminPanel;
